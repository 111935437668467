import { CopyButton, Spinner } from '@harmoney/ui-design-system';
import {
  AssetAndLiability,
  BPMNSimulation,
  BPMNSimulationStatusEnum,
  FinancialProfile,
  IncomeAndExpense,
  NetworthSource,
  Prisma,
} from '@prisma/client';

import { AffordabilityDetailsForSimulation } from './AffordabilityDetailsForSimulation';

export const SimulationDetails = ({
  simulationDetails,
  financialProfileData,
}: {
  simulationDetails: BPMNSimulation & { existingVariables: Prisma.JsonValue };
  financialProfileData: FinancialProfile & {
    asset_and_liability: (AssetAndLiability & {
      networthSource: NetworthSource;
    })[];
    income_and_expense: (IncomeAndExpense & {
      networthSource: NetworthSource;
    })[];
  };
}) => {
  const statusColorMap = {
    [BPMNSimulationStatusEnum.new]: 'text-grey-5',
    [BPMNSimulationStatusEnum.in_progress]: 'text-secondary',
    [BPMNSimulationStatusEnum.success]: 'text-success',
    [BPMNSimulationStatusEnum.error]: 'text-error',
  };

  return (
    <>
      {simulationDetails?.variables && financialProfileData && (
        <AffordabilityDetailsForSimulation
          rootData={simulationDetails?.variables}
          financialProfileData={financialProfileData}
        />
      )}

      <h2 className="flex items-center">
        <span>Simulation status</span>:{' '}
        <span className="flex items-center ml-2">
          <span className={statusColorMap[simulationDetails.status]}>{simulationDetails.status} </span>
          <span className="ml-4">
            {' '}
            {simulationDetails.status === BPMNSimulationStatusEnum.in_progress && <Spinner size="small" />}
          </span>
        </span>
      </h2>
      {simulationDetails.notes && <p>Simulation notes: {simulationDetails.notes}</p>}
      <h3 className="text-grey-5 font-medium">Comparison of data between simulation and live BPMN</h3>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <h4 className="font-bold">Simulatation</h4>
          <div className="bg-black p-4 rounded relative mt-4">
            <CopyButton
              valueToCopy={JSON.stringify(simulationDetails.variables, null, 2)}
              className="absolute right-2 text-white"
            />
            <code className="text-white whitespace-pre-wrap font-mono">
              {JSON.stringify(simulationDetails.variables, null, 2)}
            </code>
          </div>
        </div>

        <div>
          <h4 className="font-bold">Live</h4>
          <div className="bg-black p-4 rounded relative mt-4">
            <CopyButton
              valueToCopy={JSON.stringify(simulationDetails.existingVariables, null, 2)}
              className="absolute right-2 text-white"
            />
            <code className="text-white whitespace-pre-wrap font-mono">
              {JSON.stringify(simulationDetails.existingVariables, null, 2)}
            </code>
          </div>
        </div>
      </div>
    </>
  );
};
