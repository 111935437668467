import { DownloadEventDto } from '@harmoney/api-interfaces';

import { emptySplitApi } from './empty-api';

const statementOfAccountApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getStatementOfAccountPDF: builder.query<
      string,
      { loanApplicationId: string; requestedStartDate: string; requestedEndDate: string }
    >({
      query: ({ loanApplicationId, requestedStartDate, requestedEndDate }) => ({
        url: `/statement-of-account/${loanApplicationId}/document-url`,
        method: 'GET',
        params: {
          requestedStartDate,
          requestedEndDate,
        },
        responseHandler: 'text',
      }),
    }),
    getStatementOfAccountPDFOfCustomerForAdmin: builder.query<
      string,
      { loanApplicationId: string; requestedStartDate: string; requestedEndDate: string }
    >({
      query: ({ loanApplicationId, requestedStartDate, requestedEndDate }) => ({
        url: `/statement-of-account/admin/${loanApplicationId}/document-url`,
        method: 'GET',
        params: {
          requestedStartDate,
          requestedEndDate,
        },
        responseHandler: 'text',
      }),
    }),
    getStatementOfAccountDownloadHistory: builder.query<DownloadEventDto[], string>({
      query: (loanApplicationId) => ({
        url: `/statement-of-account/admin/${loanApplicationId}/download-history`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useLazyGetStatementOfAccountPDFQuery,
  useLazyGetStatementOfAccountPDFOfCustomerForAdminQuery,
  useGetStatementOfAccountDownloadHistoryQuery,
} = statementOfAccountApi;
