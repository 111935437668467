export enum SpokeName {
  LOAN_VARIATION = 'loan-variation',
  DIRECT_DEBIT_SUSPENSION = 'direct-debit-suspension',
  SCHEDULE_EXTRA_PAYMENT = 'schedule-extra-payment',
  UPDATE_DIRECT_DEBIT_PLAN = 'update-direct-debit-plan',
  UPDATE_DIRECT_DEBIT_BANK_ACCOUNT = 'update-direct-debit-bank-account',
  UPDATE_COLLECTION_STATUS = 'update-collection-status',
  WRITE_OFF_LOAN = 'write-off',
  EDIT_WRITE_OFF_LOAN = 'edit-write-off',
}
