import Link from 'next/link';
import React, { useState } from 'react';
import { ICancellationReasonFields } from '@harmoney/api-interfaces';
import { PreQualifiedQuoteData } from '@harmoney/api-interfaces';
import { LoanApplicationQueryResponse, UserInstanceQueryResponse } from '@harmoney/redux';
import {
  ArrowCircleRightIcon,
  Button,
  Card,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  IconV2,
} from '@harmoney/ui-design-system';
import { hasReachedQuote, loanStatusFormatter } from '@harmoney/ui-utils';
import { LoanApplicationStatusEnum } from '@prisma/client';
import { EntryCollection } from 'contentful';
import dayjs from 'dayjs';

import { CancelQuoteDialog } from '../../CancelQuoteDialog';
import { LoanDetails } from '../LoanDetails';

import { QuoteProgress } from './QuoteProgress';

type Props = {
  loanApplication: LoanApplicationQueryResponse;
  userInstances: UserInstanceQueryResponse;
  cancelReasons: EntryCollection<ICancellationReasonFields>;
  mainProcessData: { processId: string };
  preQualifiedQuoteData?: PreQualifiedQuoteData;
  interestRate?: number;
};

const QuoteCard = ({
  loanApplication,
  userInstances,
  cancelReasons,
  mainProcessData,
  preQualifiedQuoteData,
  interestRate,
}: Props) => {
  const [showCancelQuoteDialog, setShowCancelQuoteDialog] = useState(false);
  const { id, status, quoteExpiryAt, loanProduct, loanProductId } = loanApplication;

  return (
    <>
      <Card key={id} className="flex flex-col items-stretch !p-0 md:px-16">
        <div className="border-b-warning bg-warning-fill flex flex-row justify-between border-b p-4">
          <span className="flex flex-col">
            <span className="font-medium">{loanStatusFormatter(status)}</span>
            {status !== LoanApplicationStatusEnum.quote_in_progress && quoteExpiryAt && (
              <span className="text-sm">
                Quote expires on <strong>{dayjs(quoteExpiryAt).subtract(2, 'days').format('DD MMMM YYYY')}</strong> at
                11:59PM.
              </span>
            )}
          </span>
          {status === LoanApplicationStatusEnum.quote_offered && loanApplication.originalLoanApplicationId === null && (
            <DropdownMenu>
              <DropdownMenuTrigger>
                <Button
                  icon={
                    <IconV2 icon="mdi:dots-vertical" width={24} height={24} className="text-grey-4 hover:text-grey-5" />
                  }
                />
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem onClick={() => setShowCancelQuoteDialog(true)}>Cancel quote</DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </div>
        <LoanDetails
          loanApplication={loanApplication}
          userInstances={userInstances}
          preQualifiedQuoteData={preQualifiedQuoteData}
          interestRate={interestRate}
        />
        {status === LoanApplicationStatusEnum.quote_offered && loanApplication.originalLoanApplicationId !== null && (
          <QuoteProgress data={userInstances} />
        )}
        {status === LoanApplicationStatusEnum.quote_in_progress && <QuoteProgress data={userInstances} />}
        <div className="flex flex-col items-stretch justify-center space-y-4 p-6">
          {status === LoanApplicationStatusEnum.quote_in_review && (
            <p>
              <span className="font-medium">Your quote is in review</span>
              <br />
              <span className="text-sm">You will receive an email as soon as your quote is ready.</span>
            </p>
          )}
          {status !== LoanApplicationStatusEnum.quote_in_review &&
            loanApplication.originalLoanApplicationId === null && (
              <Link href={`journey?journeyId=${mainProcessData.processId}&productId=${loanProductId}`} passHref>
                <Button alignIcon="end" icon={<ArrowCircleRightIcon size="large" />} isFullWidth variant="primary">
                  {hasReachedQuote(status) ? 'GET YOUR MONEY' : 'CONTINUE'}
                </Button>
              </Link>
            )}
          {status === LoanApplicationStatusEnum.quote_in_progress &&
            loanApplication.originalLoanApplicationId === null && (
              <Button variant="text" isFullWidth onClick={() => setShowCancelQuoteDialog(true)}>
                CANCEL QUOTE
              </Button>
            )}
          {status === LoanApplicationStatusEnum.quote_offered && loanApplication.originalLoanApplicationId !== null && (
            <>
              <Link href={`journey?journeyId=${loanProduct.processId}&productId=${loanProductId}`} passHref>
                <Button alignIcon="end" icon={<ArrowCircleRightIcon size="large" />} isFullWidth variant="primary">
                  CONTINUE
                </Button>
              </Link>
              <Button variant="text" isFullWidth onClick={() => setShowCancelQuoteDialog(true)}>
                CANCEL QUOTE
              </Button>
            </>
          )}
        </div>
      </Card>
      <CancelQuoteDialog
        type="quote"
        applicationId={id}
        applicationStatus={status}
        productName={loanProduct?.name}
        showCancelQuoteDialog={showCancelQuoteDialog}
        setCancelQuoteDialog={setShowCancelQuoteDialog}
        reasons={cancelReasons}
      />
    </>
  );
};

export default QuoteCard;
