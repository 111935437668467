import { useMemo } from 'react';
import { BankAccountDto, LoanApplicationRepaymentDetailDto } from '@harmoney/api-interfaces';
import { CommonOptionProps, ControlledSelect, Label, useFormContext } from '@harmoney/ui-design-system';
import { Icon } from '@iconify/react';

import { maskNormalizedBankAccount, normalizeBankAccount } from '../utils';

import { BankAccountConnectionIssueAlert } from './BankAccountConnectIssueAlert';

type BankAccountType = 'loanToBankAccount' | 'repaymentFromBankAccount' | 'updateDirectDebitToBankAccount';

interface BankAccountSelectorBaseProps {
  bankAccountType: BankAccountType;
  maskBankDetails?: boolean;
}

type BankAccountSelectorProps = BankAccountSelectorBaseProps &
  (
    | { repaymentDetail: LoanApplicationRepaymentDetailDto; bankAccounts?: never }
    | { bankAccounts: BankAccountDto[]; repaymentDetail?: never }
  );

const BankAccountSelectorLabel = ({ bankAccountType }: { bankAccountType: BankAccountType }) => {
  const labelMapper: Record<BankAccountType, JSX.Element> = {
    loanToBankAccount: (
      <Label htmlFor="loanToBankAccount" className="font-medium mb-2">
        Paid to
      </Label>
    ),
    repaymentFromBankAccount: (
      <>
        <Label htmlFor="repaymentFromBankAccount" className="font-medium mb-2">
          Repaid from
        </Label>
        <p className="text-sm text-grey-4">We will automatically set up recurring repayments via direct debit.</p>
      </>
    ),
    updateDirectDebitToBankAccount: (
      <Label htmlFor="updateDirectDebitToBankAccount" className="font-normal leading-6 mb-2">
        Update direct debit account to
      </Label>
    ),
  };

  return labelMapper[bankAccountType] || null;
};

export const BankAccountSelector = (props: BankAccountSelectorProps) => {
  const { register } = useFormContext();

  const bankAccountOptions = useMemo(() => {
    const options: CommonOptionProps[] = [{ label: 'Select a bank account', value: '', disabled: true }];

    const bankAccounts = props.repaymentDetail ? props.repaymentDetail.bankAccounts : props.bankAccounts;

    if (!bankAccounts || bankAccounts.length === 0) return options;

    const accounts: CommonOptionProps[] = bankAccounts.map(({ accountNumber, bankName, bsb, bankLogoUrl }) => {
      const bankAccount = props.maskBankDetails
        ? maskNormalizedBankAccount(bsb, accountNumber)
        : normalizeBankAccount(bsb, accountNumber);

      return {
        ...(bankLogoUrl ? { imageSrc: bankLogoUrl } : { icon: <Icon icon="mdi:bank" fontSize="2rem" /> }),
        label: (
          <span className="flex flex-col">
            <span>{bankName}</span>
            <span className="text-sm">{bankAccount}</span>
          </span>
        ),
        value: normalizeBankAccount(bsb, accountNumber),
        width: 100,
      };
    });

    if (props.bankAccountType === 'updateDirectDebitToBankAccount') {
      const addAnotherBankAccount: CommonOptionProps = {
        label: <span>Add another bank account</span>,
        value: 'ADD_ANOTHER_BANK_ACCOUNT',
      };

      return [...options, ...accounts, addAnotherBankAccount];
    }

    return [...options, ...accounts];
  }, [props]);

  if (bankAccountOptions && bankAccountOptions.length <= 1) {
    return <BankAccountConnectionIssueAlert />;
  }

  return (
    <>
      <BankAccountSelectorLabel bankAccountType={props.bankAccountType} />
      {bankAccountOptions && <ControlledSelect {...register(props.bankAccountType)} options={bankAccountOptions} />}
    </>
  );
};
