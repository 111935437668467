import Link from 'next/link';
import React, { useState } from 'react';
import { ICancellationReasonFields } from '@harmoney/api-interfaces';
import { PreQualifiedQuoteData } from '@harmoney/api-interfaces';
import { LoanApplicationQueryResponse, UserInstanceQueryResponse } from '@harmoney/redux';
import { ArrowCircleRightIcon, Button, Card } from '@harmoney/ui-design-system';
import { LoanApplicationStatusEnum } from '@prisma/client';
import { EntryCollection } from 'contentful';
import dayjs from 'dayjs';

import { CancelQuoteDialog } from '../../CancelQuoteDialog';
import { LoanDetails } from '../LoanDetails';

import { ApplicationProgress } from './ApplicationProgress';

type Props = {
  loanApplication: LoanApplicationQueryResponse;
  userInstances: UserInstanceQueryResponse;
  cancelReasons: EntryCollection<ICancellationReasonFields>;
  mainProcessData: { processId: string };
  preQualifiedQuoteData?: PreQualifiedQuoteData;
  interestRate?: number;
};

const ApplicationCard = ({
  loanApplication,
  userInstances,
  cancelReasons,
  mainProcessData,
  preQualifiedQuoteData,
  interestRate,
}: Props) => {
  const { id, status, applicationExpiryAt, loanProduct, loanProductId } = loanApplication;

  const [showCancelApplicationDialog, setShowCancelApplicationDialog] = useState(false);

  return (
    <>
      <Card key={id} className="flex flex-col items-stretch !p-0 md:px-16">
        <div className="border-b-secondary-lighter-1 bg-secondary-lighter-3 flex flex-col border-b p-4">
          <span className="font-medium">Application submitted</span>
          {status === LoanApplicationStatusEnum.application_in_progress && applicationExpiryAt && (
            <span className="text-sm">
              Application expires on{' '}
              <strong>{dayjs(applicationExpiryAt).subtract(2, 'day').format('DD MMMM YYYY')}</strong> at 11:59PM.
            </span>
          )}
        </div>
        <LoanDetails
          loanApplication={loanApplication}
          userInstances={userInstances}
          preQualifiedQuoteData={preQualifiedQuoteData}
          interestRate={interestRate}
        />
        <ApplicationProgress data={userInstances} />
        <div className="flex flex-col items-stretch justify-center space-y-4 p-6">
          <Link href={`journey?journeyId=${mainProcessData.processId}&productId=${loanProductId}`} passHref>
            <Button alignIcon="end" icon={<ArrowCircleRightIcon size="large" />} isFullWidth variant="primary">
              CONTINUE
            </Button>
          </Link>
          <Button variant="text" isFullWidth onClick={() => setShowCancelApplicationDialog(true)}>
            CANCEL APPLICATION
          </Button>
        </div>
      </Card>
      <CancelQuoteDialog
        type="application"
        applicationId={id}
        applicationStatus={status}
        productName={loanProduct?.name}
        showCancelQuoteDialog={showCancelApplicationDialog}
        setCancelQuoteDialog={setShowCancelApplicationDialog}
        reasons={cancelReasons}
      />
    </>
  );
};

export default ApplicationCard;
