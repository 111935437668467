import { BranchEnum } from '@prisma/client';
import { Document, Font, Image, Link, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import dayjs from 'dayjs';

import {
  AU_FOOTER_TEXT_DISCLAIMER,
  AU_FOOTER_TEXT_INFO,
  AU_OFFICE_ADDRESS,
  NZ_FOOTER_DISCLAIMER,
  NZ_FOOTER_INFO,
  NZ_OFFICE_ADDRESS,
} from '../../utils/constants';

interface POQTemplateProps {
  data: any;
  fonts?: any;
}

export const PayOffQuoteTemplate = ({ data, fonts }: POQTemplateProps) => {
  Font.register({
    family: 'GothamHTF-Bold',
    src: fonts?.gothamBold?.url,
  });
  Font.register({ family: 'GothamHTF-Book', src: fonts?.gothamBook?.url });

  const styles = StyleSheet.create({
    page: {
      paddingVertical: 20,
      paddingHorizontal: 25,
      paddingBottom: '110px',
      backgroundColor: '#f5f5f5',
    },
    headerText: {
      color: '#434343',
    },
    headerLogo: {
      paddingBottom: '15px',
      width: '110px',
      height: 'auto',
      backgroundColor: '#f5f5f5',
    },
    content: {
      backgroundColor: '#ffffff',
      paddingLeft: '20px',
      paddingRight: '20px',
      paddingTop: '10px',
    },
    centerAlign: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    primaryText: {
      color: '#ff4b4a',
    },
    container: {
      border: '1px solid #ccc',
      borderRadius: 5,
      padding: '10px',
      fontSize: '10px',
      marginTop: '15px',
    },
    header: {
      fontWeight: 'bold',
      paddingBottom: 5,
      paddingTop: 5,
    },
    instruction: {
      marginBottom: 5,
      marginTop: 5,
    },
    payIdDetails: {
      marginTop: 10,
      paddingTop: 10,
      borderTop: '1px solid #ddd',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    label: {
      color: '#888',
      marginBottom: 2,
    },
    email: {
      fontWeight: 'bold',
    },
    flexRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  });
  return (
    <Document>
      <Page style={[styles.page, { fontFamily: 'GothamHTF-Book', fontSize: '10px', lineHeight: 1.35 }]}>
        <View style={styles.centerAlign} fixed>
          <Image src="https://assets.harmoney.com/logos/hmy-logo-wide-498x114.png" style={styles.headerLogo} />
        </View>
        <View style={styles.content}>
          <Text style={{ ...styles.headerText, marginTop: '10px' }}>{dayjs().format('DD MMMM YYYY')}</Text>
          <Text style={styles.headerText}>
            {data.userProfile.firstName} {data.userProfile.lastName}
          </Text>
          <Text style={styles.headerText}>
            {data.address.streetNumber} {data.address.streetName}
          </Text>
          <Text style={styles.headerText}>{data.address.suburb}</Text>
          <Text style={styles.headerText}>
            {data.address.state} {data.address.postalCode}
          </Text>
          <Text
            style={{
              fontFamily: 'GothamHTF-Bold',
              fontSize: '14px',
              paddingTop: '20px',
            }}
          >
            Hi {data.userProfile.firstName}
          </Text>

          <Text
            style={{
              ...styles.primaryText,
              fontFamily: 'GothamHTF-Bold',
              fontSize: '14px',
              paddingTop: '10px',
            }}
          >
            Re: Payout Quote {data.loanNumber}
          </Text>
          <View>
            <Text style={{ fontFamily: 'GothamHTF-Book', fontSize: '10px', paddingTop: '10px' }}>
              We have received your request for a payout quote on your loan {data.loanNumber}.
            </Text>
            <Text style={{ fontFamily: 'GothamHTF-Book', fontSize: '10px' }}>
              Based on your current loan status, your payout amount is ${data.payoutAmount}.
            </Text>
            <Text style={{ fontFamily: 'GothamHTF-Book', fontSize: '10px', paddingTop: '10px' }}>
              This figure will only be valid on the {dayjs().format('DD MMMM YYYY')}. If you wish to payout your loan on
              a different date, please contact us as the payout figure needs to be recalculated.
            </Text>
            {data.branch === BranchEnum.AU && (
              <View style={styles.container}>
                <View
                  style={{ ...styles.flexRow, paddingBottom: '5px', borderBottom: '1px solid #ddd', width: '100%' }}
                >
                  <Text
                    style={{
                      ...styles.header,
                      fontFamily: 'GothamHTF-Bold',
                      fontSize: '10px',
                    }}
                  >
                    Pay with PayID
                  </Text>
                  <Image
                    src="https://production-harmoney-cms-shared-assets.s3.ap-southeast-2.amazonaws.com/public/Logos/payID-logo.png"
                    style={{ width: 43, height: 20 }}
                  />
                </View>
                <Text style={{ ...styles.instruction, fontFamily: 'GothamHTF-Book', fontSize: '10px' }}>
                  1. Log in to your online banking app.
                </Text>
                <Text style={{ ...styles.instruction, fontFamily: 'GothamHTF-Book', fontSize: '10px' }}>
                  2. Choose <Text style={{ fontFamily: 'GothamHTF-Bold' }}>pay by email address.</Text>
                </Text>
                <Text style={{ ...styles.instruction, fontFamily: 'GothamHTF-Book', fontSize: '10px' }}>
                  3. Transfer the amount to your Harmoney PayID email using the details below.
                </Text>
                <View style={styles.payIdDetails}>
                  <Text style={{ ...styles.label, fontFamily: 'GothamHTF-Book', fontSize: '10px' }}>
                    Your Harmoney PayID
                  </Text>
                  <Text style={{ ...styles.email, fontFamily: 'GothamHTF-Bold', fontSize: '10px' }}>
                    {data.payIdEmail}
                  </Text>
                </View>
              </View>
            )}

            {data.branch === BranchEnum.AU ? (
              <View style={styles.container}>
                <Text
                  style={{
                    fontFamily: 'GothamHTF-Bold',
                    fontSize: '10px',
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    borderBottom: '1px solid #ddd',
                  }}
                >
                  Pay with Bank Transfer
                </Text>
                <Text
                  style={{
                    fontFamily: 'GothamHTF-Book',
                    fontSize: '10px',
                    borderBottom: '1px solid #ddd',
                    paddingTop: '5px',
                    paddingBottom: '5px',
                  }}
                >
                  If your bank does not offer PayID, you can pay by manual bank transfer.
                </Text>
                <View style={styles.flexRow}>
                  <Text style={{ fontFamily: 'GothamHTF-Book', fontSize: '10px', paddingTop: '10px' }}>BSB</Text>
                  <Text style={{ fontFamily: 'GothamHTF-Bold', fontSize: '10px', paddingTop: '10px' }}>
                    {data.harmoneyBsb}
                  </Text>
                </View>
                <View style={styles.flexRow}>
                  <Text style={{ fontFamily: 'GothamHTF-Book', fontSize: '10px', paddingTop: '10px' }}>
                    Account Number
                  </Text>
                  <Text style={{ fontFamily: 'GothamHTF-Bold', fontSize: '10px', paddingTop: '10px' }}>
                    {data.harmoneyAccountNumber}
                  </Text>
                </View>
                <View style={styles.flexRow}>
                  <Text style={{ fontFamily: 'GothamHTF-Book', fontSize: '10px', paddingTop: '10px' }}>Ref</Text>
                  <Text style={{ fontFamily: 'GothamHTF-Bold', fontSize: '10px', paddingTop: '10px' }}>
                    {data.loanNumber}
                  </Text>
                </View>
              </View>
            ) : (
              <View style={styles.container}>
                <Text
                  style={{
                    fontFamily: 'GothamHTF-Bold',
                    fontSize: '10px',
                    paddingTop: '15px',
                    paddingBottom: '15px',
                    borderBottom: '1px solid #ddd',
                  }}
                >
                  Pay with Bank Transfer
                </Text>
                <Text
                  style={{
                    fontFamily: 'GothamHTF-Book',
                    fontSize: '10px',
                    borderBottom: '1px solid #ddd',
                    paddingTop: '15px',
                    paddingBottom: '15px',
                  }}
                >
                  If your bank does not offer PayID, you can pay by manual bank transfer.
                </Text>
                <View style={styles.flexRow}>
                  <Text style={{ fontFamily: 'GothamHTF-Book', fontSize: '10px', paddingTop: '20px' }}>Bank</Text>
                  <Text style={{ fontFamily: 'GothamHTF-Bold', fontSize: '10px', paddingTop: '20px' }}>ASB</Text>
                </View>
                <View style={styles.flexRow}>
                  <Text style={{ fontFamily: 'GothamHTF-Book', fontSize: '10px', paddingTop: '20px' }}>
                    Account Number
                  </Text>
                  <Text style={{ fontFamily: 'GothamHTF-Bold', fontSize: '10px', paddingTop: '20px' }}>
                    12-3109-0159350-03
                  </Text>
                </View>
                <View style={styles.flexRow}>
                  <Text style={{ fontFamily: 'GothamHTF-Book', fontSize: '10px', paddingTop: '20px' }}>Reference</Text>
                  <Text style={{ fontFamily: 'GothamHTF-Bold', fontSize: '10px', paddingTop: '20px' }}>
                    {data.loanNumber}
                  </Text>
                </View>
                <View style={styles.flexRow}>
                  <Text style={{ fontFamily: 'GothamHTF-Book', fontSize: '10px', paddingTop: '20px' }}>Payee Name</Text>
                  <Text style={{ fontFamily: 'GothamHTF-Bold', fontSize: '10px', paddingTop: '20px' }}>
                    Harmoney Collections Trust
                  </Text>
                </View>
              </View>
            )}
            {data.branch === BranchEnum.AU ? (
              <>
                <Text style={{ fontFamily: 'GothamHTF-Book', fontSize: '10px', paddingTop: '10px' }}>
                  For alternate payment methods please call us on 1300 042 766 or email{' '}
                  <Link src="mailto:customerservice@harmoney.com.au">customerservice@harmoney.com.au</Link>.
                </Text>
                <Text style={{ fontFamily: 'GothamHTF-Book', fontSize: '10px', paddingTop: '10px' }}>
                  Kind regards,
                </Text>
                <Text style={{ fontFamily: 'GothamHTF-Bold', fontSize: '10px', paddingTop: '10px' }}>
                  Team harmoney
                </Text>
              </>
            ) : (
              <>
                <Text style={{ fontFamily: 'GothamHTF-Book', fontSize: '10px', paddingTop: '20px' }}>
                  For alternate payment methods please call us on 0800 427 666 or email{' '}
                  <Link src="mailto:customerservice@harmoney.com.au">customerservice@harmoney.co.nz</Link>.
                </Text>
                <Text style={{ fontFamily: 'GothamHTF-Book', fontSize: '10px', paddingTop: '20px' }}>
                  Kind regards,
                </Text>
                <Text
                  style={{ fontFamily: 'GothamHTF-Bold', fontSize: '10px', paddingBottom: '20px', paddingTop: '20px' }}
                >
                  Team harmoney
                </Text>
              </>
            )}
          </View>
        </View>

        <View
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            paddingTop: 20,
            paddingBottom: 20,
            paddingLeft: 25,
            paddingRight: 25,
            backgroundColor: '#141414',
          }}
          fixed
        >
          {data.branch === BranchEnum.AU ? (
            <View>
              <Text style={{ fontFamily: 'GothamHTF-Book', fontSize: '8px', textAlign: 'center', color: 'white' }}>
                {AU_OFFICE_ADDRESS}
              </Text>
              <Text
                style={{
                  fontFamily: 'GothamHTF-Book',
                  fontSize: '6px',
                  textAlign: 'center',
                  marginTop: '5px',
                  borderBottom: '1px solid #ccc',
                  color: 'white',
                  paddingBottom: '10px',
                }}
              >
                <Link style={{ color: 'white' }} src="https://www.harmoney.com.au">
                  Our website
                </Link>{' '}
                |{' '}
                <Link style={{ color: 'white' }} src="https://www.harmoney.com.au/contact">
                  Contact us
                </Link>
              </Text>
              <Text
                style={{
                  fontFamily: 'GothamHTF-Book',
                  fontSize: '8px',
                  marginTop: '10px',
                  textAlign: 'justify',
                  color: 'white',
                }}
              >
                {AU_FOOTER_TEXT_INFO}
              </Text>
              <View
                style={{
                  fontFamily: 'GothamHTF-Book',
                  fontSize: '6px',
                  marginTop: '10px',
                  textAlign: 'justify',
                  color: 'white',
                }}
              >
                <Text>
                  <Text
                    style={{
                      fontFamily: 'GothamHTF-Bold',
                    }}
                  >
                    Disclaimer:{' '}
                  </Text>
                  <Text>{AU_FOOTER_TEXT_DISCLAIMER}</Text>
                </Text>
              </View>
            </View>
          ) : (
            <View>
              <Text style={{ fontFamily: 'GothamHTF-Book', fontSize: '8px', textAlign: 'center', color: 'white' }}>
                {NZ_OFFICE_ADDRESS}
              </Text>
              <Text
                style={{
                  fontFamily: 'GothamHTF-Book',
                  fontSize: '6px',
                  textAlign: 'center',
                  marginTop: '5px',
                  borderBottom: '1px solid #ccc',
                  color: 'white',
                  paddingBottom: '10px',
                }}
              >
                <Link style={{ color: 'white' }} src="https://www.harmoney.co.nz">
                  Our website
                </Link>{' '}
                |{' '}
                <Link style={{ color: 'white' }} src="https://www.harmoney.co.nz/about-us/contact">
                  Contact us
                </Link>
              </Text>
              <Text
                style={{
                  fontFamily: 'GothamHTF-Book',
                  fontSize: '8px',
                  marginTop: '10px',
                  textAlign: 'justify',
                  color: 'white',
                }}
              >
                {NZ_FOOTER_INFO}
              </Text>
              <View
                style={{
                  fontFamily: 'GothamHTF-Book',
                  fontSize: '6px',
                  marginTop: '10px',
                  textAlign: 'justify',
                  color: 'white',
                }}
              >
                <Text>
                  <Text
                    style={{
                      fontFamily: 'GothamHTF-Bold',
                    }}
                  >
                    Disclaimer:{' '}
                  </Text>
                  <Text>{NZ_FOOTER_DISCLAIMER}</Text>
                </Text>
              </View>
            </View>
          )}
        </View>
      </Page>
    </Document>
  );
};
