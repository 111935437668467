import { Slide, toast } from 'react-toastify';

export const downloadPdf = (url) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `StatementOfAccount.pdf`);
  link.setAttribute('target', '_blank');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export enum DownloadError {
  SOA_ERROR = 'There was a problem downloading your statement of account. Please reach out to our customer service and we will send it to you directly.',
  POQ_ERROR = 'There was a problem downloading your pay off quote.',
  POQ_NOTIFICATION = 'Error: This loan has a Direct Debit scheduled for today or a pending payment. The Pay off Quote will need to be generated manually.',
}

export const handleDownloadError = (error: DownloadError) => {
  toast.error(`${error}`, {
    position: 'bottom-center',
    autoClose: false,
    closeButton: true,
    draggable: false,
    className: 'border bg-error-fill border-error !p-4 !mb-4 !mx-2 rounded-lg',
    bodyClassName: 'bg-error-fill !m-0 text-grey-5 text-sm',
    transition: Slide,
  });
};
