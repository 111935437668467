import { CollectionStatusDto, DownloadEventDto, RepaymentScheduleDto } from '@harmoney/api-interfaces';
import { DATE_TIME_FORMAT } from '@harmoney/ui-utils';
import { LoanWriteOffActionEnum, PaymentStatusEnum } from '@prisma/client';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(isSameOrAfter);

export enum ChangeSummaryTypeEnum {
  SUSPEND_DIRECT_DEBIT = 'Suspend direct debit',
  ONE_OFF_PAYMENT = 'Scheduled extra payment',
  UPDATE_DIRECT_DEBIT_PLAN = 'Updated direct debit plan',
  UPDATE_COLLECTION_STATUS = 'Updated collection status',
  WRITE_OFF = 'Write off',
  EDIT_WRITE_OFF = 'Write off ',
  DOWNLOAD_STATEMENT_OF_ACCOUNT = 'Statement of Account',
  DOWNLOAD_PAY_OFF_QUOTE = 'Pay Off Quote (same day)',
}

interface Schedule {
  id: string;
  scheduledAt: string;
  amount: number;
  status: string;
}

interface GroupedPayments {
  spokeId: string;
  purpose: string;
  otherPurpose: string;
  note: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  cancelledAt: string;
  cancelledBy: string;
  type: ChangeSummaryTypeEnum;
  schedules: Schedule[];
}

interface GetFinalPaymentPlanDataParams {
  repaymentScheduleHistory: RepaymentScheduleDto;
  collectionStatusHistory?: CollectionStatusDto[];
  statementOfAccountDownloadHistory?: DownloadEventDto[];
  payOffQuoteDownloadHistory?: DownloadEventDto[];
}

export const PERMANENT_DIRECT_DEBIT_SUSPENSION_YEAR = 2099;

export const getChangeSummaryData = ({
  repaymentScheduleHistory,
  collectionStatusHistory,
  statementOfAccountDownloadHistory,
  payOffQuoteDownloadHistory,
}: GetFinalPaymentPlanDataParams) => {
  const aggChangeSummaryData: any[] = [];

  if (collectionStatusHistory) {
    collectionStatusHistory.forEach((collectionStatus) => {
      aggChangeSummaryData.push({
        ...collectionStatus,
        type: ChangeSummaryTypeEnum.UPDATE_COLLECTION_STATUS,
      });
    });
  }

  if (statementOfAccountDownloadHistory) {
    statementOfAccountDownloadHistory.forEach((downloadEvt) => {
      aggChangeSummaryData.push({
        ...downloadEvt,
        type: ChangeSummaryTypeEnum.DOWNLOAD_STATEMENT_OF_ACCOUNT,
      });
    });
  }

  if (payOffQuoteDownloadHistory) {
    payOffQuoteDownloadHistory.forEach((downloadEvt) => {
      aggChangeSummaryData.push({
        ...downloadEvt,
        type: ChangeSummaryTypeEnum.DOWNLOAD_PAY_OFF_QUOTE,
      });
    });
  }

  if (repaymentScheduleHistory) {
    repaymentScheduleHistory?.directDebitSuspensions?.forEach((suspension) => {
      aggChangeSummaryData.push({
        ...suspension,
        type: ChangeSummaryTypeEnum.SUSPEND_DIRECT_DEBIT,
      });
    });

    const groupedPayments: { [key: string]: GroupedPayments } = repaymentScheduleHistory?.oneoffRepayments?.reduce(
      (acc: { [key: string]: GroupedPayments }, payment) => {
        const key = `${dayjs(payment.createdAt).format(DATE_TIME_FORMAT)}-${payment.createdBy}-${payment.purpose}`;
        if (!acc[key]) {
          acc[key] = {
            spokeId: payment.spokeId,
            purpose: payment.purpose,
            otherPurpose: payment.otherPurpose,
            note: payment.note,
            createdAt: payment.createdAt ? payment.createdAt.toString() : null,
            createdBy: payment.createdBy,
            updatedAt: payment.updatedAt ? payment.updatedAt.toString() : null,
            updatedBy: payment.updatedBy,
            cancelledAt: payment.cancelledAt ? payment.cancelledAt.toString() : null,
            cancelledBy: payment.cancelledBy,
            schedules: [],
            type: ChangeSummaryTypeEnum.ONE_OFF_PAYMENT,
          };
        }
        acc[key].schedules.push({
          id: payment.id,
          scheduledAt: payment.scheduledAt,
          amount: payment.amount,
          status: payment.status,
        });
        return acc;
      },
      {}
    );

    if (groupedPayments) {
      Object.values(groupedPayments).forEach((group) => {
        group.schedules.sort((a, b) => {
          return new Date(a.scheduledAt).getTime() - new Date(b.scheduledAt).getTime();
        });
        aggChangeSummaryData.push(group);
      });
    }

    repaymentScheduleHistory?.directDebitPlans?.forEach((plan) => {
      aggChangeSummaryData.push({
        ...plan,
        type: ChangeSummaryTypeEnum.UPDATE_DIRECT_DEBIT_PLAN,
      });
    });

    if (repaymentScheduleHistory?.writeOff?.length === 1) {
      const writeOff = repaymentScheduleHistory.writeOff[0];
      aggChangeSummaryData.push({
        ...writeOff,
        type: ChangeSummaryTypeEnum.WRITE_OFF,
      });
    } else if (repaymentScheduleHistory?.writeOff?.length > 1) {
      const edit = repaymentScheduleHistory?.writeOff?.filter(
        (writeOff) => writeOff.actionType === LoanWriteOffActionEnum.edit
      );

      const create = repaymentScheduleHistory?.writeOff?.filter(
        (writeOff) => writeOff.actionType === LoanWriteOffActionEnum.create
      );

      const editWriteOff = edit.map((writeOff) => {
        return {
          ...writeOff,
          type: ChangeSummaryTypeEnum.EDIT_WRITE_OFF,
        };
      });

      const createWriteOff = create.map((writeOff) => {
        return {
          ...writeOff,
          type: ChangeSummaryTypeEnum.WRITE_OFF,
        };
      });

      editWriteOff.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());

      aggChangeSummaryData.push({
        ...createWriteOff[0],
        editWriteOff,
      });
    }

    aggChangeSummaryData.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

    return aggChangeSummaryData;
  }
};

export const getCurrentOneOffPaymentDates = (finalPaymentPlanData): string[] => {
  if (!finalPaymentPlanData) return [];

  return finalPaymentPlanData
    .filter((item) => item.type === ChangeSummaryTypeEnum.ONE_OFF_PAYMENT)
    .flatMap((payment) => payment.schedules)
    .filter(
      (schedule) =>
        dayjs(schedule.scheduledAt).isSameOrAfter(dayjs(), 'day') &&
        (schedule.status === PaymentStatusEnum.new ||
          schedule.status === PaymentStatusEnum.success ||
          schedule.status === PaymentStatusEnum.processing)
    )
    .map((schedule) => dayjs(schedule.scheduledAt).format('YYYY-MM-DD'));
};

export const isAllPaymentsDeleted = (oneOffRepayments) => {
  return oneOffRepayments?.schedules?.every((schedule) => schedule?.status === PaymentStatusEnum.cancelled);
};

export const isAllPaymentsCompleted = (oneOffRepayments) => {
  return oneOffRepayments?.schedules?.every((schedule) => schedule?.status === PaymentStatusEnum.success);
};

export const isDDSuspensionDeleted = (directDebitSuspension) => {
  return directDebitSuspension?.isActive === false;
};

export const isSomePaymentsDeleted = (oneOffRepayments) => {
  return oneOffRepayments?.schedules?.some((schedule) => schedule?.status === PaymentStatusEnum.cancelled);
};

export const isChangeSummaryItemClickable = (tableRowType) => {
  return tableRowType !== ChangeSummaryTypeEnum.UPDATE_COLLECTION_STATUS;
};

export const getCurrentPendingOneOffPaymentAmount = (finalPaymentPlanData) => {
  if (!finalPaymentPlanData) return [];

  return finalPaymentPlanData
    .filter((item) => item.type === ChangeSummaryTypeEnum.ONE_OFF_PAYMENT)
    .flatMap((payment) => payment.schedules)
    .filter(
      (schedule) =>
        dayjs(schedule.scheduledAt).isSameOrAfter(dayjs(), 'day') &&
        (schedule.status === PaymentStatusEnum.new || schedule.status === PaymentStatusEnum.processing)
    )
    .map((schedule) => schedule.amount)
    .reduce((acc, amount) => acc + amount, 0);
};
