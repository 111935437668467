import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

import { Button, IconV2 } from '@harmoney/ui-design-system';
import { useAuth0 } from '@auth0/auth0-react';
import { UserDto } from '@harmoney/api-interfaces';

import { AdminNav } from '../AdminNav/AdminNav';
import { NavBar } from '../NavBar/NavBar';
interface HeaderProps {
  autoHideMenu?: boolean;
  isAdmin?: boolean;
  user?: UserDto;
  search?: React.ReactNode;
}

export const Header = ({ autoHideMenu = true, isAdmin = false, user, search }: HeaderProps) => {
  const { loginWithRedirect: login, isLoading, isAuthenticated, user: authUser } = useAuth0();
  const {
    query: { auto_login: autoLogin },
  } = useRouter();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navbarRef = useRef<HTMLDivElement>(null);
  const btnRef = useRef<HTMLDivElement>(null);
  const roles = authUser?.['https://stellare.harmoney.com/roles'];
  const isAuthAdmin = roles?.includes('admin');

  useEffect(() => {
    if (autoLogin) {
      login({ authorizationParams: { screen_hint: 'signup' } });
    }
  }, [autoLogin, login]);

  const handleToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    if (autoHideMenu) {
      const handleOutsideClick = (event: MouseEvent) => {
        if (
          navbarRef.current &&
          !navbarRef?.current?.contains(event.target as Node) &&
          !btnRef?.current?.contains(event.target as Node)
        ) {
          setIsMenuOpen(false);
        }
      };
      document.addEventListener('mousedown', handleOutsideClick);
      return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
      };
    }
    return;
  }, [autoHideMenu]);

  return (
    <header className="fixed z-40 mb-6 flex w-full flex-col">
      <div className="shadow-drop z-40 flex w-full flex-row bg-white py-2 px-4">
        <div
          className={classNames(
            isAdmin ? 'flex flex-row justify-between items-center' : 'grid grid-cols-2 items-center md:grid-cols-3',
            'w-full'
          )}
        >
          {isAdmin ? (
            <AdminNav roles={roles} />
          ) : (
            <>
              <div className="hidden md:col-span-1 md:block"></div>
              <Link href="/" className="col-span-1 flex justify-self-start md:justify-self-center">
                <img
                  className="md:hidden"
                  width="48"
                  height="48"
                  src="/assets/logos/hmy-coral-logo.svg"
                  alt="Harmoney Logo"
                />
                <img
                  className="hidden md:block"
                  width="210"
                  height="48"
                  src="/assets/logos/hmy-coral-logo-lg.svg"
                  alt="Harmoney Logo"
                />
              </Link>
            </>
          )}

          {!isLoading && (
            <div className="col-span-1 justify-self-end flex flex-row items-center">
              {isAuthenticated ? (
                <>
                  {isAuthAdmin && search}
                  <div ref={btnRef}>
                    <Button
                      data-testid="menu-btn"
                      icon={
                        isMenuOpen ? (
                          <IconV2 icon="material-symbols:close-rounded" width={32} height={32} />
                        ) : (
                          <IconV2 icon="material-symbols:menu-rounded" width={32} height={32} />
                        )
                      }
                      className="hover:!bg-secondary-lighter-3 !text-secondary !min-h-[3rem] !py-[0.25rem] !px-[0.4rem]"
                      onClick={handleToggle}
                    />
                  </div>
                </>
              ) : (
                <Button
                  alignIcon="start"
                  className="!min-w-fit !max-w-fit"
                  icon={<IconV2 icon="material-symbols:person-outline" className="text-secondary" width={30} />}
                  variant="secondary"
                  onClick={() => login()}
                >
                  Sign In
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="relative z-30 flex flex-row justify-end" ref={navbarRef}>
        {isMenuOpen && <NavBar handleToggle={handleToggle} user={user} />}
      </div>
    </header>
  );
};
