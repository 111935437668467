import { useGetRepaymentDetailForUnutilisedLimitQuery } from '@harmoney/redux';

import { CommonProps } from '../../common-props';
import { WizardChildProps } from '../../Wizard';

import { PaymentForm } from './components';

interface PaymentProps extends CommonProps, WizardChildProps {}

export function PaymentForUnutilisedLimit({ taskId, taskFriendlyURL, onNext, completeTaskWithData }: PaymentProps) {
  return (
    <PaymentForm
      taskId={taskId}
      taskFriendlyURL={taskFriendlyURL}
      isUnutilisedLimitLoan={true}
      onNext={onNext}
      repaymentDetailQuery={useGetRepaymentDetailForUnutilisedLimitQuery}
      completeTaskWithData={completeTaskWithData}
    />
  );
}
