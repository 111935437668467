import { useRouter } from 'next/router';
import { useState } from 'react';
import { useFriendlyURL } from '@harmoney/hooks';
import { Alert, Button } from '@harmoney/ui-design-system';

import { CommonProps } from '../../common-props';

export function IdentityFailure({ taskId, completeTaskWithData, taskFriendlyURL }: CommonProps) {
  const router = useRouter();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleBackToDashboard = () => {
    setIsSubmitting(true);
    completeTaskWithData({ taskId });
    router.push('/dashboard');
  };

  useFriendlyURL(taskFriendlyURL);

  return (
    <>
      <Alert variant="error" title="Too many tries">
        We are not able to verify your ID due to too many failed attempts.
      </Alert>
      <div className="flex justify-center">
        <Button as="button" variant="secondary" onClick={handleBackToDashboard} isLoading={isSubmitting} hasShadow>
          Back to dashboard
        </Button>
      </div>
    </>
  );
}
