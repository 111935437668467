import Image from 'next/image';
import { useState } from 'react';
import { BankAccountDetails } from '@harmoney/api-interfaces';
import { ArrowCircleRightIcon, Button, Dialog } from '@harmoney/ui-design-system';
import { bankLogoUrl } from '@harmoney/ui-utils';
import { Icon } from '@iconify/react';

import { maskNormalizedBankAccount } from '../../../fulfilment/Payment/utils';

type ModalProps = {
  from: Array<BankAccountDetails>;
  to: BankAccountDetails;
  shouldMask?: boolean;
  onSubmit: () => void | Promise<void>;
  onClose?: () => void | Promise<void>;
};

function FromBankAccountLoans(details: BankAccountDetails) {
  return (
    <div className="border border-grey-3 rounded-lg flex flex-col gap-1 p-4">
      <span>{details.productName}</span>
      <small>{details.loanId}</small>
    </div>
  );
}

function ToBankAccount(details: BankAccountDetails & { shouldMask?: boolean }) {
  const maskedBankAccount = maskNormalizedBankAccount(details.accountBsb, details.accountNumber);

  return (
    <div className="border border-grey-3 rounded-lg flex flex-row gap-4 p-4 items-center justify-start">
      {(!!details.bankSlug && (
        <Image
          width={-1}
          height={-1}
          className="w-1/6"
          src={bankLogoUrl(details.bankSlug)}
          alt={'Bank logo of ' + details.bankSlug}
        ></Image>
      )) || <Icon icon="mdi:bank" fontSize="2rem" className="w-12" />}

      <div className="flex flex-col gap-1">
        <span>{details.bankName || 'No bank name specified'}</span>
        <small>{details.shouldMask ? maskedBankAccount : `${details.accountBsb}-${details.accountNumber}`}</small>
      </div>
    </div>
  );
}

export default function DirectDebitUpdateConfirmModal(props: ModalProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isClosed, setIsClosed] = useState(false);

  function onClose() {
    setIsClosed(true);
    props.onClose?.();
  }

  return (
    <Dialog open={!isClosed} onOpenChange={onClose} modal showCloseButton title="Update direct debit account">
      <div className="flex flex-col gap-4 p-4">
        <span>Updated for the below loan(s)</span>
        <div className="flex flex-col gap-2">
          {props.from.map((details, idx) => (
            <FromBankAccountLoans key={idx} {...details} />
          ))}
        </div>
        <span>Direct debit account:</span>
        <ToBankAccount shouldMask={props.shouldMask} {...props.to} />
        <div className="inline-flex flex-row justify-center items-end gap-4">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            alignIcon="end"
            variant="primary"
            type="submit"
            isLoading={isSubmitting}
            icon={<ArrowCircleRightIcon size="large" />}
            onClick={async () => {
              setIsSubmitting(true);
              await props.onSubmit();
              setIsSubmitting(false);
            }}
          >
            Update
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
