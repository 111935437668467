import {
  Frequency,
  LoanApplicationRepaymentDetailDto,
  ResponseResult,
  SavePaymentDetailDto,
} from '@harmoney/api-interfaces';
import { LoanApplicationStatusEnum } from '@prisma/client';

import { emptySplitApi } from './empty-api';

const unutilisedLimitApi = emptySplitApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    /**
     * @deprecated This endpoint is deprecated and could be removed after 01/01/2025
     */
    savePaymentForUnutilisedLimit: builder.mutation<void, SavePaymentDetailDto>({
      query: (paymentDetail) => ({
        url: `/unutilised-limit/loan-applications/${paymentDetail.repaymentSchedule.loanApplicationId}/payment`,
        method: 'POST',
        body: paymentDetail,
      }),
    }),
    submitLoanApplicationForUnutilisedLimit: builder.mutation<
      ResponseResult,
      {
        id: string;
        termInMonths?: number;
        fundedAmount?: number;
        repaymentFrequency?: Frequency;
        status?: LoanApplicationStatusEnum;
      }
    >({
      query: (params) => ({
        url: `/unutilised-limit/loan-applications`,
        method: 'PATCH',
        body: params,
      }),
    }),
    getRepaymentDetailForUnutilisedLimit: builder.query<LoanApplicationRepaymentDetailDto, string>({
      query: (loanApplicationId) => ({
        url: `/unutilised-limit/loan-applications/${loanApplicationId}/repayment-detail`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useSavePaymentForUnutilisedLimitMutation,
  useSubmitLoanApplicationForUnutilisedLimitMutation,
  useGetRepaymentDetailForUnutilisedLimitQuery,
} = unutilisedLimitApi;
