import { CompleteTaskDto, TaskDetailDto, UserInstanceVariables } from '@harmoney/api-interfaces';
import { omit } from 'lodash';

import { emptySplitApi } from './empty-api';

const taskApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    completeTask: builder.mutation<boolean, { taskId: string; variables? }>({
      query: ({ taskId, variables = {} }) => ({
        url: `/tasks/${taskId}`,
        method: 'PATCH',
        body: { variables: JSON.stringify(variables) },
      }),
    }),
    completeTaskWithData: builder.mutation<void, CompleteTaskDto>({
      query: (taskData) => ({
        url: `/tasks/${taskData.taskId}`,
        method: 'POST',
        body: omit(taskData, 'taskId'),
      }),
    }),
    getCurrentTask: builder.query<TaskDetailDto, { userId: string; journeyId: string }>({
      query: ({ userId, journeyId }) => ({
        url: `/tasks/current-task?userId=${userId}&journeyId=${journeyId}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
    }),
    getCurrentInternalTask: builder.query<TaskDetailDto, { userId: string; journeyId: string; instanceId: string }>({
      query: ({ userId, journeyId, instanceId }) => ({
        url: `/tasks/current-internal-task?userId=${userId}&journeyId=${journeyId}&instanceId=${instanceId}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
    }),
    getTask: builder.query<TaskDetailDto, string>({
      query: (id) => ({
        url: `/tasks/${id}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
    }),
    getVariables: builder.query<UserInstanceVariables, string>({
      query: (id) => ({
        url: `/tasks/${id}/variables`,
        method: 'GET',
      }),
    }),
    resetAllUserTasks: builder.mutation<void, { userId: string }>({
      query: ({ userId }) => ({
        url: `/tasks/reset/${userId}`,
        method: 'PATCH',
      }),
    }),
  }),
});

export const {
  useCompleteTaskMutation,
  useGetCurrentTaskQuery,
  useLazyGetCurrentInternalTaskQuery,
  useLazyGetCurrentTaskQuery,
  useGetTaskQuery,
  useGetVariablesQuery,
  useResetAllUserTasksMutation,
  useCompleteTaskWithDataMutation,
} = taskApi;

export type CompleteTaskWithDataType = ReturnType<typeof taskApi.endpoints.completeTaskWithData.useMutation>[0];
