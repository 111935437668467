import { useEffect, useMemo } from 'react';
import { relationshipStatusMapper, ResidencyStatusMapping } from '@harmoney/api-interfaces';
import {
  useAppSelector,
  useGetPreQualifiedQuotesByUserIdQuery,
  useGetUserByIdQuery,
  useGetUserProfileForAdminQuery,
} from '@harmoney/redux';
import { CollapsibleCard } from '@harmoney/ui-design-system';
import { booleanToYesNo, DATE_FORMAT, valueOrDash } from '@harmoney/ui-utils';
import { Address, Prisma, User, UserProfile } from '@prisma/client';
import dayjs from 'dayjs';

import { Field } from './shared';

interface CustomerDetailsProps {
  userProfileData?: UserProfile & { addresses: Address[] };
  userData?: User;
  userId?: string;
  affordabilityData?: Prisma.JsonValue;
}

// TODO: Add postcode to Address object ?
const formatAddressLine = (address: Address & { postcode?: string }) => {
  if (!address) return '';

  if (address.streetNumber || address.streetName) {
    return `${address.streetNumber || ''} ${address.streetName || ''}, ${
      address.suburb ? `${address.suburb},` : ''
    } ${address.state || ''} ${address.postalCode || address?.postcode} ${address.country}`.trim();
  }

  return '';
};

export const CustomerDetails = (params: CustomerDetailsProps) => {
  const userId = params.userId;
  const token = useAppSelector((state) => state.accessToken.value);
  const { data: userDataResult } = useGetUserByIdQuery(userId as string, { skip: !token || !userId });
  const { data: userProfileDataResult } = useGetUserProfileForAdminQuery(userId as string, { skip: !token || !userId });
  const { data: preQualifiedQuotes } = useGetPreQualifiedQuotesByUserIdQuery(
    { userId: userId as string },
    { skip: !userId }
  );

  const latestPQ = useMemo(() => {
    if (!preQualifiedQuotes?.length) return null;
    return preQualifiedQuotes[0];
  }, [preQualifiedQuotes]);

  const userProfileData = useMemo(() => {
    if (params.userProfileData) {
      return params.userProfileData;
    }
    if (userProfileDataResult) {
      return userProfileDataResult;
    }
  }, [params, userProfileDataResult]);
  const userData = useMemo(() => {
    if (params.userData) {
      return params.userData;
    }
    if (userDataResult) {
      return userDataResult;
    }
  }, [params, userDataResult]);

  const addresses = useMemo(() => {
    if (userProfileData?.addresses?.length) {
      return userProfileData.addresses.map((address) => ({
        id: address.id,
        addressLine: formatAddressLine(address),
        isCurrent: address.isCurrent,
        residentialStatus: address.residentialStatus,
      }));
    }

    if (latestPQ?.address) {
      return Array.isArray(latestPQ.address)
        ? latestPQ.address.map((address) => ({
            addressLine: formatAddressLine(address as unknown as Address),
            isCurrent: true,
            residentialStatus: latestPQ.residentialStatus,
          }))
        : [];
    }

    return [];
  }, [userProfileData?.addresses, latestPQ]);

  useEffect(() => {
    document.title = userData?.businessKey;
  }, [userData]);

  const currentAddress = addresses?.find((address) => address.isCurrent);
  const affordabilityDataForUser = params.affordabilityData?.['user'];

  return (
    <CollapsibleCard title="Customer Details" className="mb-6" disabled>
      <div className="grid grid-cols-2 px-4">
        <div className="border-r-grey-1 border-r-2 p-4">
          <Field className="grid-cols-[30%_70%]">
            <span>Customer Id:</span>
            <span className="pr-6 break-words">{valueOrDash(userData?.id)}</span>
          </Field>
          <Field className="grid-cols-[30%_70%]">
            <span>Customer Number:</span>
            <span>{valueOrDash(userData?.businessKey)}</span>
          </Field>
          <Field className="grid-cols-[30%_70%]">
            <span>Account status:</span>
            <span>{userData?.accountStatus}</span>
          </Field>
          <Field className="grid-cols-[30%_70%]">
            <span>First name:</span>
            <span>{valueOrDash(userProfileData?.firstName || latestPQ?.firstName)}</span>
          </Field>
          <Field className="grid-cols-[30%_70%]">
            <span>Middle name:</span>
            <span>{valueOrDash(userProfileData?.middleName || latestPQ?.middleName)}</span>
          </Field>
          <Field className="grid-cols-[30%_70%]">
            <span>Last name:</span>
            <span>{valueOrDash(userProfileData?.lastName || latestPQ?.lastName)}</span>
          </Field>
          <Field className="grid-cols-[30%_70%]">
            <span>Residency status:</span>
            <span>
              {valueOrDash(
                ResidencyStatusMapping[
                  affordabilityDataForUser?.residencyStatus?.toUpperCase() ??
                    userData?.residencyStatus ??
                    latestPQ?.residentialStatus
                ]
              )}
            </span>
          </Field>
          <Field className="grid-cols-[30%_70%]">
            <span>Relationship status:</span>
            <span>
              {
                relationshipStatusMapper[
                  affordabilityDataForUser?.relationshipStatus ??
                    userProfileData?.relationshipStatus ??
                    latestPQ?.relationshipStatus
                ]
              }
            </span>
          </Field>
          <Field className="grid-cols-[30%_70%]">
            <span>Residential status:</span>
            <span className="capitalize">
              {valueOrDash(
                affordabilityDataForUser?.residentialStatus ??
                  currentAddress?.residentialStatus ??
                  latestPQ?.residentialStatus
              )}
            </span>
          </Field>
          <Field className="grid-cols-[30%_70%]">
            <span>Dependants:</span>
            <span>
              {affordabilityDataForUser?.numberOfDependants ??
                userProfileData?.numberOfDependants ??
                latestPQ?.numberOfDependents}
            </span>
          </Field>
        </div>
        <div className="p-4 pr-12">
          <Field className="grid grid-cols-[30%_70%]">
            <span>Email address:</span>
            <span className="break-all">{valueOrDash(userData?.email)}</span>
          </Field>
          <Field className="grid grid-cols-[30%_70%]">
            <span>Email verified:</span>
            <span>{booleanToYesNo(userData?.emailVerified)}</span>
          </Field>
          <Field className="grid grid-cols-[30%_70%]">
            <span>Mobile phone:</span>
            <span>{valueOrDash(userData?.mobilePhoneNumber)}</span>
          </Field>
          <Field className="grid grid-cols-[30%_70%]">
            <span>DOB:</span>
            <span>
              {userProfileData?.dateOfBirth || latestPQ?.dateOfBirth
                ? dayjs(userProfileData?.dateOfBirth || latestPQ?.dateOfBirth).format(DATE_FORMAT)
                : '-'}
            </span>
          </Field>
          <Field className="grid grid-cols-[30%_70%]">
            <span>Age:</span>
            <span>
              {userProfileData?.dateOfBirth || latestPQ?.dateOfBirth
                ? dayjs().diff(userProfileData?.dateOfBirth || latestPQ?.dateOfBirth, 'year')
                : '-'}
            </span>
          </Field>
          <Field className="grid grid-cols-[30%_70%]">
            <span>Current Address:</span>
            <span>{valueOrDash(currentAddress?.addressLine)}</span>
          </Field>
          {addresses?.length > 1 && (
            <Field className="grid grid-cols-[30%_70%]">
              <span>Previous Address(es):</span>
              <span>{valueOrDash(addresses?.find((address) => !address.isCurrent)?.addressLine)}</span>
            </Field>
          )}
        </div>
      </div>
    </CollapsibleCard>
  );
};
