import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useState } from 'react';
import {
  branchCountryMapper,
  branchEmojiMapper,
  loanStatusEmojiMapper,
  loanStatusMapper,
  loanStatusShortLabelMapper,
  opportunityMapper,
  preQualifiedQuoteFilterEmojiMapper,
  preQualifiedQuoteFilterLabelMapper,
  preQualifiedQuoteFilterValueMapper,
} from '@harmoney/api-interfaces';
import { LoanApplicationAdminQueryResponse, useAppSelector, useGetAllApplicationsQuery } from '@harmoney/redux';
import { isPreview, isProduction } from '@harmoney/ui-app-shell';
import { Button, CopyButton, Spinner, UncontrolledCheckbox } from '@harmoney/ui-design-system';
import { DATE_TIME_FORMAT, isApplication, isQuote, SOMETHING_WENT_WRONG } from '@harmoney/ui-utils';
import { Icon } from '@iconify/react';
import { readLocalStorageValue, useDebouncedValue } from '@mantine/hooks';
import { LoanApplicationStatusEnum } from '@prisma/client';
import { ColumnDef, PaginationState } from '@tanstack/react-table';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';

import { DataTable, DEFAULT_FULL_COLUMN_VISIBILITY } from '../DataTable';
import { DataTableForSimulation } from '../DataTableForSimulation';

/**
 * To make the first two columns sticky, we need to set a defined width for the first column.
 * This ensures that the table layout remains unaffected by the sticky columns. The padding is 32px.
 *
 * Tailwind CSS does not support variables or dynamic values directly inside its utility classes.
 * Hence, we need to use a ternary operator to conditionally apply the width value based on the preview mode.
 */
const fixedWidthForFirstColumn = {
  width: isPreview() ? 'w-[180px]' : 'w-[150px]',
  minWidthWithPadding: isPreview() ? 'min-w-[212px]' : 'min-w-[182px]',
  leftWidthWithPadding: isPreview() ? 'left-[212px]' : 'left-[182px]',
};

const APPLICATION_TABLE_CUSTOMISE_COLUMNS_LOCAL_STORAGE_KEY = 'customised-columns-state:applications-table';

export const ApplicationDataTableForSimulation = () => {
  const token = useAppSelector((state) => state.accessToken.value);
  const router = useRouter();
  const [globalFilter, setGlobalFilter] = useState('');

  const updateFiltersInUrl = (
    statusFilter: string,
    productFilter: string,
    branchFilter: string,
    opportunityFilter: string,
    preQualifiedQuoteFilter: string
  ) => {
    router.push({
      pathname: router.pathname,
      query: { ...router.query, branchFilter, productFilter, opportunityFilter, statusFilter, preQualifiedQuoteFilter },
    });
  };

  const defaultColumnVisibility = readLocalStorageValue<Record<string, boolean>>({
    key: APPLICATION_TABLE_CUSTOMISE_COLUMNS_LOCAL_STORAGE_KEY,
    defaultValue: DEFAULT_FULL_COLUMN_VISIBILITY,
  });

  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [productFilter, setProductFilter] = useState<string[]>([]);
  const [branchFilter, setBranchFilter] = useState<string[]>([]);
  const [opportunityFilter, setOpportunityFilter] = useState<string[]>([]);
  const [staffFilter, setStaffFilter] = useState<string>(isProduction() ? 'harmoney' : undefined);
  const [preQualifiedQuoteFilter, setPreQualifiedQuoteFilter] = useState<string[]>([
    preQualifiedQuoteFilterValueMapper.HAS_PQ,
  ]);
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const [indeterminate, setIndeterminate] = useState<boolean>(false);
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const [debounced] = useDebouncedValue(globalFilter, 400);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 100,
  });

  const {
    data: allApplicationsData,
    isError,
    isLoading,
  } = useGetAllApplicationsQuery(
    {
      page: pageIndex + 1,
      limit: pageSize,
      search: encodeURI(debounced),
      filter: JSON.stringify({
        staffFilter,
        statusFilter: statusFilter?.join(','),
        productFilter,
        branchFilter: branchFilter?.join(','),
        opportunityFilter: opportunityFilter?.join(','),
        preQualifiedQuoteFilter: preQualifiedQuoteFilter?.join(','),
      }),
    },
    {
      skip:
        !token || !statusFilter || !productFilter || !branchFilter || !opportunityFilter || !preQualifiedQuoteFilter,
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (router.query.statusFilter) {
      setStatusFilter((router.query.statusFilter as string)?.split(','));
    }
    if (router.query.productFilter) {
      setProductFilter((router.query.productFilter as string)?.split(','));
    }
    if (router.query.branchFilter) {
      setBranchFilter((router.query.branchFilter as string)?.split(','));
    }
    if (router.query.opportunityFilter) {
      setOpportunityFilter((router.query.opportunityFilter as string)?.split(','));
    }
    if (router.query.preQualifiedQuoteFilter) {
      setPreQualifiedQuoteFilter((router.query.preQualifiedQuoteFilter as string)?.split(','));
    }
  }, [
    router.query.statusFilter,
    router.query.productFilter,
    router.query.branchFilter,
    router.query.opportunityFilter,
    router.query.preQualifiedQuoteFilter,
  ]);

  const columns = useMemo<ColumnDef<LoanApplicationAdminQueryResponse>[]>(
    () => [
      {
        id: 'select',
        header: ({ table }) => (
          <UncontrolledCheckbox
            name="select-all"
            checked={table.getIsAllPageRowsSelected() || table.getIsSomePageRowsSelected()}
            onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
            aria-label="Select all"
          />
        ),
        cell: ({ row }) => (
          <UncontrolledCheckbox
            name="Select row"
            checked={row.getIsSelected()}
            onCheckedChange={(value) => row.toggleSelected(!!value)}
            aria-label="Select row"
          />
        ),
        enableSorting: false,
        enableHiding: false,
      },
      {
        header: 'Link to Simulation',
        accessorKey: 'businessKey',
        cell: ({ row }) => (
          <div className="flex flex-row gap-2">
            <Link
              href={`/admin/simulation/details?userId=${row.original.userId}&applicationId=${row.original.id}`}
              passHref
              target="_blank"
            >
              {row.original.businessKey}
            </Link>
            <CopyButton valueToCopy={row.original.businessKey} size="small" />
          </div>
        ),
      },
      {
        header: 'Customer number',
        accessorKey: 'user.businessKey',
        cell: ({ row }) => (
          <div className="flex flex-row gap-2">
            <Link href={`/admin/details?userId=${row.original.userId}#customer-profile`} target="_blank" passHref>
              {row.original?.user?.businessKey}
            </Link>
            <CopyButton valueToCopy={row.original?.user?.businessKey} size="small" />
          </div>
        ),
        enableSorting: false,
        enableHiding: false,
      },
      {
        header: 'ID',
        accessorKey: 'id',
        cell: ({ row }) => (
          <div className="flex flex-row gap-2">
            <Link
              href={`/admin/details?userId=${row.original.userId}#applications/${row.original.id}`}
              passHref
              className="w-[10rem]"
              target="_blank"
            >
              {row.original.id}
            </Link>
            <CopyButton valueToCopy={row.original.id} size="small" />
          </div>
        ),
        enableSorting: false,
      },
      {
        header: 'Branch',
        accessorKey: 'branch',
        cell: ({ row }) => <span>{row.original.branch}</span>,
      },
      {
        header: 'Product',
        accessorKey: 'loanProductName',
        cell: ({ row }) => <span>{row.original?.loanProduct?.name}</span>,
      },
      {
        header: 'Opportunity',
        accessorKey: 'originationType',
        cell: ({ row }) => <span>{opportunityMapper[row.original?.originationType] ?? '-'}</span>,
      },
      {
        header: 'Status',
        accessorKey: 'status',
        cell: ({ row }) => (
          <span className="inline-block w-32">{`${loanStatusEmojiMapper[row.original.status]} - ${loanStatusMapper[row.original.status]}`}</span>
        ),
      },
      {
        header: 'Created on',
        accessorFn: (row) => dayjs(row.createdAt).format(DATE_TIME_FORMAT),
        sortingFn: (a, b) => (dayjs(a.original.createdAt).isBefore(b.original.createdAt) ? 1 : -1),
      },
      {
        header: 'Updated on',
        accessorFn: (row) => dayjs(row.updatedAt).format(DATE_TIME_FORMAT),
        sortingFn: (a, b) => (dayjs(a.original.updatedAt).isBefore(b.original.updatedAt) ? 1 : -1),
      },
    ],
    []
  );

  const statusFilterObject = useMemo(() => {
    const status = Object.keys(loanStatusShortLabelMapper).map((key) => ({
      id: key,
      label: `${loanStatusEmojiMapper[key]} ${capitalize(loanStatusShortLabelMapper[key])}`,
      checked: !statusFilter?.includes(key),
    }));
    return [
      {
        id: 'quote',
        label: 'Quote status',
        options: status.filter((s) => isQuote(s.id as LoanApplicationStatusEnum)),
      },
      {
        id: 'application',
        label: 'Application status',
        options: status.filter((s) => isApplication(s.id as LoanApplicationStatusEnum)),
      },
    ];
  }, []);

  const productFilterObject = useMemo(
    () => [
      {
        id: 'Personal loan',
        label: 'Personal loan',
        checked: !productFilter?.includes('Personal loan'),
      },
      {
        id: 'Debt consolidation',
        label: 'Debt consolidation',
        checked: !productFilter?.includes('Debt consolidation'),
      },
    ],
    [productFilter]
  );

  const branchFilterObject = useMemo(() => {
    return Object.keys(branchCountryMapper).map((key) => ({
      id: key,
      label: `${branchEmojiMapper[key]} ${branchCountryMapper[key]}`,
      checked: !branchFilter?.includes(key),
    }));
  }, [branchFilter]);

  const opportunityFilterObject = useMemo(() => {
    return Object.keys(opportunityMapper).map((key) => ({
      id: key,
      label: opportunityMapper[key],
      checked: !opportunityFilter?.includes(key),
    }));
  }, [opportunityFilter]);

  const preQualifiedQuoteFilterObject = useMemo(() => {
    return Object.keys(preQualifiedQuoteFilterLabelMapper).map((key) => ({
      id: preQualifiedQuoteFilterValueMapper[key],
      label: `${preQualifiedQuoteFilterEmojiMapper[key]} ${preQualifiedQuoteFilterLabelMapper[key]}`,
      checked: !preQualifiedQuoteFilter?.includes(preQualifiedQuoteFilterValueMapper[key]),
    }));
  }, [preQualifiedQuoteFilter]);

  useEffect(() => {
    statusFilterObject.forEach((filter) => {
      filter.options.forEach((option) => {
        option.checked = !statusFilter.includes(option.id);
      });
    });
    productFilterObject.forEach((option) => {
      option.checked = !productFilter.includes(option.id);
    });
    branchFilterObject.forEach((option) => {
      option.checked = !branchFilter.includes(option.id);
    });
    opportunityFilterObject.forEach((option) => {
      option.checked = !opportunityFilter.includes(option.id);
    });
    preQualifiedQuoteFilterObject.forEach((option) => {
      option.checked = !preQualifiedQuoteFilter.includes(option.id);
    });
  }, [
    statusFilter,
    productFilter,
    branchFilter,
    opportunityFilter,
    preQualifiedQuoteFilter,
    statusFilterObject,
    productFilterObject,
    branchFilterObject,
    opportunityFilterObject,
    preQualifiedQuoteFilterObject,
  ]);

  const handleBranchChange = (id: string) => {
    const filteredBranch = branchFilterObject.map((branch) => {
      if (branch.id === id) {
        branch.checked = !branch.checked;
      }
      return branch;
    });
    const branchIds = filteredBranch.filter((branch) => !branch.checked).map((branch) => branch.id);
    setBranchFilter(branchIds);
  };

  const handleProductChange = (id: string) => {
    const filteredProduct = productFilterObject.map((product) => {
      if (product.id === id) {
        product.checked = !product.checked;
      }
      return product;
    });
    const productIds = filteredProduct.filter((product) => !product.checked).map((product) => product.id);
    setProductFilter(productIds);
  };

  const handlePreQualifiedQuoteChange = (id: string) => {
    const filteredPQ = preQualifiedQuoteFilterObject.map((pq) => {
      if (pq.id === id) {
        pq.checked = !pq.checked;
      }
      return pq;
    });

    const pqIds = filteredPQ.filter((pq) => !pq.checked).map((pq) => pq.id);

    setPreQualifiedQuoteFilter(pqIds);
  };

  const handleOpportunityChange = (id: string) => {
    const filteredOpportunity = opportunityFilterObject.map((opportunity) => {
      if (opportunity.id === id) {
        opportunity.checked = !opportunity.checked;
      }
      return opportunity;
    });

    const opportunityIds = filteredOpportunity
      .filter((opportunity) => !opportunity.checked)
      .map((opportunity) => opportunity.id);

    setOpportunityFilter(opportunityIds);
  };

  const handleStatusChange = (id: string) => {
    const filteredStatus = statusFilterObject.map((filter) => {
      filter.options.map((option) => {
        if (option.id === id) {
          option.checked = !option.checked;
        }
      });
      return filter;
    });
    const statusIds = filteredStatus
      .map((filter) => filter.options.filter((option) => !option.checked).map((option) => option.id))
      .flat();
    setStatusFilter(statusIds);
  };

  const areAllFiltersEmpty =
    statusFilter.length === 0 &&
    productFilter.length === 0 &&
    branchFilter.length === 0 &&
    opportunityFilter.length === 0 &&
    preQualifiedQuoteFilter.length === 0;
  const areFiltersUnderMaxLimit =
    statusFilter.length < 11 ||
    productFilter.length < 2 ||
    branchFilter.length < 2 ||
    opportunityFilter.length < 3 ||
    preQualifiedQuoteFilter.length < 1;

  useEffect(() => {
    if (areAllFiltersEmpty) {
      setAllChecked(true);
      setIndeterminate(false);
    } else if (areFiltersUnderMaxLimit) {
      setAllChecked(false);
      setIndeterminate(true);
    } else {
      setAllChecked(false);
      setIndeterminate(false);
    }
  }, [
    statusFilter,
    productFilter,
    branchFilter,
    opportunityFilter,
    preQualifiedQuoteFilter,
    areAllFiltersEmpty,
    areFiltersUnderMaxLimit,
  ]);

  const handleSelectAll = () => {
    if (allChecked) {
      const filteredStatus = [],
        filteredProduct = [],
        filteredBranch = [],
        filteredOpportunity = [],
        filteredPreQualifiedQuote = [];
      productFilterObject.forEach((option) => {
        option.checked = false;
        filteredProduct.push(option.id);
      });
      branchFilterObject.forEach((option) => {
        option.checked = false;
        filteredBranch.push(option.id);
      });
      statusFilterObject.forEach((filter) => {
        filter.options.forEach((option) => {
          option.checked = false;
          filteredStatus.push(option.id);
        });
      });
      opportunityFilterObject.forEach((option) => {
        option.checked = false;
        filteredOpportunity.push(option.id);
      });
      preQualifiedQuoteFilterObject.forEach((option) => {
        option.checked = false;
        filteredPreQualifiedQuote.push(option.id);
      });

      setStatusFilter(filteredStatus);
      setProductFilter(filteredProduct);
      setBranchFilter(filteredBranch);
      setOpportunityFilter(filteredOpportunity);
      setPreQualifiedQuoteFilter(filteredPreQualifiedQuote);

      updateFiltersInUrl(
        filteredStatus.join(','),
        filteredProduct.join(','),
        filteredBranch.join(','),
        filteredOpportunity.join(','),
        filteredPreQualifiedQuote.join(',')
      );
      setAllChecked(false);
      setIndeterminate(false);
    } else {
      statusFilterObject.forEach((filter) => {
        filter.options.forEach((option) => {
          option.checked = true;
        });
      });

      productFilterObject.forEach((option) => {
        option.checked = true;
      });
      branchFilterObject.forEach((option) => {
        option.checked = true;
      });
      preQualifiedQuoteFilterObject.forEach((option) => {
        option.checked = true;
      });

      setStatusFilter([]);
      setProductFilter([]);
      setBranchFilter([]);
      setOpportunityFilter([]);
      setPreQualifiedQuoteFilter([]);
      updateFiltersInUrl('', '', '', '', '');

      setAllChecked(true);
      setIndeterminate(false);
    }
  };

  if (isError) {
    return <div className="m-3">{SOMETHING_WENT_WRONG}</div>;
  }

  if (isLoading) {
    return (
      <div className="align-center mt-32 flex justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <div className="bg-grey-1 flex min-h-dvh">
        <div
          className={classNames(
            'transition-width duration-300 border-r border-r-grey-2 relative p-6',
            collapsed ? 'min-w-6 min-h-dvh' : 'col-span-2'
          )}
        >
          <div className={classNames(collapsed ? 'hidden' : 'block')}>
            <h2>Filters</h2>
            <UncontrolledCheckbox
              name="all-application-status"
              label="Select all / Clear all"
              alignLabel="right"
              className="font-medium text-sm mb-4"
              checked={indeterminate === true ? 'indeterminate' : allChecked}
              onCheckedChange={handleSelectAll}
              checkIconSize="tiny"
              iconWidth={16}
            />
            {statusFilterObject.map((filter) => (
              <div key={filter.id} className="mb-4">
                <span className="text-base font-medium">{filter.label}</span>
                <div className="flex flex-col gap-1 mt-3">
                  {filter.options.map((option) => (
                    <div key={option.id} className="flex flex-row items-center justify-start gap-x-4 mb-2">
                      <UncontrolledCheckbox
                        name={option.id}
                        label={option.label}
                        checked={option.checked}
                        onCheckedChange={() => handleStatusChange(option.id)}
                        className="text-sm"
                        checkIconSize="tiny"
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}
            <div className="mb-4">
              <span className="text-base font-medium">Pre-qualified quote</span>
              <div className="flex flex-col gap-1 mt-3">
                {preQualifiedQuoteFilterObject.map((option) => (
                  <div key={option.id} className="flex flex-row items-center justify-start gap-x-4 mb-2">
                    <UncontrolledCheckbox
                      name={option.id}
                      label={option.label}
                      checked={option.checked}
                      onCheckedChange={() => handlePreQualifiedQuoteChange(option.id)}
                      className="text-sm"
                      checkIconSize="tiny"
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="mb-4">
              <span className="text-base font-medium">Country</span>
              <div className="flex flex-col gap-1 mt-3">
                {branchFilterObject &&
                  branchFilterObject?.map((option) => (
                    <div key={option.id} className="flex flex-row items-center justify-start gap-x-4 mb-2">
                      <UncontrolledCheckbox
                        name={option.id}
                        label={option.label}
                        checked={option.checked}
                        onCheckedChange={() => handleBranchChange(option.id)}
                        className="text-sm"
                        checkIconSize="tiny"
                      />
                    </div>
                  ))}
              </div>
            </div>
            <div className="mb-4">
              <span className="text-base font-medium">Product</span>
              <div className="flex flex-col gap-1 mt-3">
                {productFilterObject.map((option) => (
                  <div key={option.id} className="flex flex-row items-center justify-start gap-x-4 mb-2">
                    <UncontrolledCheckbox
                      name={option.id}
                      label={option.label}
                      checked={option.checked}
                      onCheckedChange={() => handleProductChange(option.id)}
                      className="text-sm"
                      checkIconSize="tiny"
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="mb-4">
              <span className="text-base font-medium">Opportunity</span>
              <div className="flex flex-col gap-1 mt-3">
                {opportunityFilterObject.map((option) => (
                  <div key={option.id} className="flex flex-row items-center justify-start gap-x-4 mb-2">
                    <UncontrolledCheckbox
                      name={option.id}
                      label={option.label}
                      checked={option.checked}
                      onCheckedChange={() => handleOpportunityChange(option.id)}
                      className="text-sm"
                      checkIconSize="tiny"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <Button
            onClick={() => setCollapsed(!collapsed)}
            variant="text"
            className="absolute top-[60px] right-[-20px] !text-grey-2"
          >
            <span className="border rounded-pill flex bg-grey-1">
              <Icon icon={`ic:round-chevron-${collapsed ? 'right' : 'left'}`} width={34} />
            </span>
          </Button>
        </div>
        <div className="p-6 flex-1 overflow-auto">
          {columns && allApplicationsData?.data && (
            <DataTable
              title="All applications"
              columns={columns}
              data={allApplicationsData.data}
              pageIndex={pageIndex}
              pageSize={pageSize}
              total={allApplicationsData?.total}
              pageCount={allApplicationsData?.page}
              setGlobalFilter={setGlobalFilter}
              globalFilter={globalFilter}
              fixedWidthForFirstColumn={fixedWidthForFirstColumn}
              setPagination={setPagination}
              defaultColumnVisibility={defaultColumnVisibility}
              customisedColumnsLocalStorageKey={APPLICATION_TABLE_CUSTOMISE_COLUMNS_LOCAL_STORAGE_KEY}
            />
          )}
          <div className="col-span-7">
            {columns && allApplicationsData?.data && (
              <DataTableForSimulation
                title="All Applications"
                columns={columns}
                data={allApplicationsData.data}
                pageIndex={pageIndex}
                pageSize={pageSize}
                total={allApplicationsData?.total}
                pageCount={allApplicationsData?.page}
                setGlobalFilter={setGlobalFilter}
                globalFilter={globalFilter}
                setPagination={setPagination}
              />
            )}
          </div>
          <div className="mt-4 flex flex-row items-center gap-1 ml-8">
            <span className="text-sm font-medium">Include Staff:</span>
            <input
              type="checkbox"
              id="staffFilter"
              name="staffFilter"
              value="staffFilter"
              defaultChecked={!isProduction()}
              onChange={(e) => setStaffFilter(e.target.checked ? undefined : 'harmoney')}
            />
          </div>
        </div>
      </div>
    </>
  );
};
