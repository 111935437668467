import { useEffect } from 'react';
import { SmartUIConfigType, useFriendlyURL, useInitialiseFrankieSmartUI } from '@harmoney/hooks';

import { CommonProps } from '../../common-props';

export function FrankieSmartUIVevo({ taskId, completeTaskWithData, taskFriendlyURL }: CommonProps) {
  const userId = useInitialiseFrankieSmartUI(taskId, SmartUIConfigType.VEVO);

  useEffect(() => {
    const handleCheckResult = (e: CustomEvent) => {
      console.log('handleCheckResult', e);
      // Business Logic when listen to checkResult
    };

    const handleCheckError = () => {
      console.log('handleCheckError');
      // Business Logic when listen to checkError
    };

    const handleSaveError = () => {
      console.log('handleSaveError');
      // Business Logic when listen to saveError
    };

    const handleExternalIDVCheckCompleted = () => {
      console.log('handleExternalIDVCheckCompleted');
      //  Business Logic when listen to externalIDVCheckCompleted
    };

    /**
     * FF_CHECK_RESULT: Emitted when retrieving a check result. We use this event to make the success screen hold for timeoutMillisecond.
     * FF_CHECK_ERROR or FF_SAVE_ERROR: Emitted when one of the two results above fail due to a System error OR lack of permissions. We use this event to make the error screen hold for timeoutMillisecond and send the verification status back to Camunda.
     * FF_SAVE_RESULT: Emitted when save is successful.
     * FF_EXTERNAL_IDV_CHECK_COMPLETED: Emitted after idScanVerification process is completed
     * More details see https://apidocs.frankiefinancial.com/docs/events
     */
    window.addEventListener('FF_CHECK_RESULT', handleCheckResult);
    window.addEventListener('FF_CHECK_ERROR', handleCheckError);
    window.addEventListener('FF_SAVE_ERROR', handleSaveError);
    window.addEventListener('FF_EXTERNAL_IDV_CHECK_COMPLETED', handleSaveError);

    return () => {
      window.removeEventListener('FF_CHECK_RESULT', handleCheckResult);
      window.removeEventListener('FF_CHECK_ERROR', handleCheckError);
      window.removeEventListener('FF_SAVE_ERROR', handleSaveError);
      window.removeEventListener('FF_EXTERNAL_IDV_CHECK_COMPLETED', handleExternalIDVCheckCompleted);
    };
  }, [completeTaskWithData, taskId, userId]);

  useFriendlyURL(taskFriendlyURL);

  return (
    <>
      <ff-onboarding-widget width="576px" height="600px" />
    </>
  );
}
