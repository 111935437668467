import { DownloadEventDto } from '@harmoney/api-interfaces';

import { emptySplitApi } from './empty-api';

const payOffQuoteApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getPayOffQuotePDFOfLoanForAdmin: builder.query<string, { loanApplicationId: string }>({
      query: ({ loanApplicationId }) => ({
        url: `/pay-off-quote/admin/${loanApplicationId}`,
        method: 'GET',
        responseHandler: 'text',
      }),
    }),
    getPayOffQuoteDownloadHistory: builder.query<DownloadEventDto[], string>({
      query: (loanApplicationId) => ({
        url: `/pay-off-quote/admin/${loanApplicationId}/download-history`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useLazyGetPayOffQuotePDFOfLoanForAdminQuery, useGetPayOffQuoteDownloadHistoryQuery } = payOffQuoteApi;
