import { ReadonlyURLSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Role, SaveUserActivityDto, SpokeDto, SpokeName } from '@harmoney/api-interfaces';
import { useJourney, useRole } from '@harmoney/hooks';
import {
  useAppDispatch,
  useAppSelector,
  useGetAllLoansByUserIdQuery,
  useGetAllSpokesQuery,
  useSaveUserActivityMutation,
} from '@harmoney/redux';
import { Alert, AlertVariant, CommonOptionProps } from '@harmoney/ui-design-system';
import {
  PaymentStatusEnum,
  PaymentTransaction,
  PaymentTypeEnum,
  UserActivityPurposeEnum,
  UserActivityTypeEnum,
} from '@prisma/client';
import classNames from 'classnames';

import { setIsBlockAccessToRepeatOpen, setIsUpdateDirectDebitAccountOpen } from '../../../../redux/slice/admin';
import { ActionCenterGroup, ActionControl } from '../ActionControl/ActionControl';
import { BlockAccessToRepeatForm } from '../BlockAccessToRepeat/BlockAccessToRepeatForm';
import { CustomerDetails } from '../CustomerDetails';
import { CustomerProfileSummary } from '../CustomerProfileSummary/CustomerProfileSummary';
import { LoanState } from '../LoanVariation/utils';
import UpdateDDBankAccount from '../UpdateDDBankAccount/UpdateDDBankAccount';
import DirectDebitHistorySidePanel from '../UpdateDDBankAccount/UpdateDDHistorySidePanel';
import DirectDebitHistoryTable from '../UpdateDDBankAccount/UpdateDDHistoryTable';

const ALERT_MESSAGES = {
  HARDBLOCK_INIT: 'Customer is blocked from repeat loans',
  HARDBLOCK_REMOVE: 'Customer has access to repeat loans if all other eligibility criteria is met',
  ERROR_UPDATE:
    'There was a problem while updating the block access to repeat loans. Please reach out to the engineering team for help.',
};

const hashSearchRegex = /#.*?(?=&|$)/g;

export const hasActiveActions = (actionCenter: Record<string, boolean>): boolean =>
  Object.values(actionCenter).some(Boolean);

const getAlertMessage = (activityType: UserActivityTypeEnum, activityPurpose: UserActivityPurposeEnum) => {
  if (activityType === UserActivityTypeEnum.REPEAT_HARDBLOCK) {
    switch (activityPurpose) {
      case UserActivityPurposeEnum.INITIALISE:
        return ALERT_MESSAGES.HARDBLOCK_INIT;
      case UserActivityPurposeEnum.REMOVE:
        return ALERT_MESSAGES.HARDBLOCK_REMOVE;
      default:
        return '';
    }
  }

  return '';
};

export const CustomerProfile = ({ userId }: { userId: string }) => {
  const router = useRouter();

  const isHardshipAdmin = useRole(Role.HARDSHIP_ADMIN);
  const token = useAppSelector((state) => state?.accessToken?.value);
  const actionCenter = useAppSelector((state) => state.admin?.actionCenter);
  const isBlockAccessToRepeatOpen = useAppSelector((state) => state.admin?.actionCenter?.isBlockAccessToRepeatOpen);
  const isShowDebitHistoryOpen = useAppSelector((state) => state.admin?.actionCenter?.showOneDirectDebitHistory);
  const isUpdateDirectDebitAccountOpen = useAppSelector(
    (state) => state.admin?.actionCenter?.isUpdateDirectDebitAccountOpen
  );
  const [spokeData, setSpokeData] = useState<SpokeDto>(null);
  const dispatch = useAppDispatch();

  const [alertMessage, setAlertMessage] = useState('');
  const [alertVariant, setAlertVariant] = useState<AlertVariant>(undefined);

  const queryParams = useMemo(() => {
    let path = router.asPath;
    path = path.replace(router.pathname, '');
    if (path.startsWith('/')) path = path.substring(1);
    return new ReadonlyURLSearchParams(path.replace(hashSearchRegex, ''));
  }, [router]);

  const taskId = useMemo(() => {
    return queryParams.get('taskId');
  }, [queryParams]);

  const [saveUserActivity] = useSaveUserActivityMutation();

  useEffect(() => {
    return () => {
      dispatch(setIsBlockAccessToRepeatOpen(false));
      dispatch(setIsUpdateDirectDebitAccountOpen(false));
    };
  }, [dispatch]);

  const { data: loans } = useGetAllLoansByUserIdQuery(userId, { skip: !userId || !token });

  const activeLoans = useMemo(() => {
    return loans?.filter((loan) => loan?.loanInformation?.loanState === LoanState.ACTIVE);
  }, [loans]);

  const { data: spokes } = useGetAllSpokesQuery(null, {
    skip: !token,
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });

  const hasDirectDebitInProgress = (paymentTransactions: PaymentTransaction[]) => {
    if (!paymentTransactions || paymentTransactions.length === 0) {
      return true;
    }

    return paymentTransactions.some(
      (transaction) =>
        (transaction.status === PaymentStatusEnum.new || transaction.status === PaymentStatusEnum.processing) &&
        transaction.paymentType === PaymentTypeEnum.directDebit
    );
  };

  const activeLoansWithNoDirectDebitInProgress = useMemo(() => {
    return activeLoans?.filter((loan) => !hasDirectDebitInProgress(loan?.paymentTransactions));
  }, [activeLoans]);

  const hasAnActiveLoanWithoutDirectDebit = activeLoansWithNoDirectDebitInProgress?.length > 0;

  const getSpokeData = useCallback(
    (spokeName: SpokeName) => {
      return spokes?.filter((spoke) => spoke.name === spokeName)[0];
    },
    [spokes]
  );

  const hasAccessPermission = useCallback(
    (spokeName: SpokeName) => {
      return spokes?.some((spoke) => spoke.name === spokeName);
    },
    [spokes]
  );

  const removeRouterParamsByNames = useCallback(
    (...params: string[]) => {
      const { pathname, query } = router;
      for (const param of params) delete query[param];
      router.replace({ pathname, query }, undefined, { shallow: true });
    },
    [router]
  );

  const actionCenterOptions: ActionCenterGroup[] = useMemo(() => {
    const commonActionCenterOptions: CommonOptionProps[] = [
      {
        label: 'Block access to repeat loans',
        value: 'block_access_to_repeat',
        onClick: () => dispatch(setIsBlockAccessToRepeatOpen(true)),
        disabled: !isHardshipAdmin,
      },
      {
        label: 'Update direct debit account',
        value: 'update_direct_debit_account',
        onClick: () => {
          setSpokeData(getSpokeData(SpokeName.UPDATE_DIRECT_DEBIT_BANK_ACCOUNT));
          dispatch(setIsUpdateDirectDebitAccountOpen(true));
        },
        disabled:
          !hasAnActiveLoanWithoutDirectDebit || !hasAccessPermission(SpokeName.UPDATE_DIRECT_DEBIT_BANK_ACCOUNT),
      },
    ];

    return [{ name: 'Actions', options: commonActionCenterOptions }];
  }, [isHardshipAdmin, hasAnActiveLoanWithoutDirectDebit, hasAccessPermission, dispatch, getSpokeData]);

  const handleUserActivitySave = async (data: SaveUserActivityDto) => {
    try {
      await saveUserActivity({ ...data, userId });
      setAlertMessage(getAlertMessage(data.activityType, data.purpose));
      dispatch(setIsBlockAccessToRepeatOpen(false));
    } catch {
      toast.error(ALERT_MESSAGES.ERROR_UPDATE, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeButton: false,
        draggable: false,
        className: 'border bg-error-fill border-error !p-4 !mx-2 rounded-lg',
        bodyClassName: 'bg-error-fill !m-0 text-grey-5 text-sm',
      });
      dispatch(setIsBlockAccessToRepeatOpen(false));
    }
  };

  useEffect(() => {
    if (!alertMessage) return;

    const timeout = setTimeout(() => {
      setAlertMessage(undefined);
      setAlertVariant(undefined);
    }, 5000);

    return () => clearTimeout(timeout);
  }, [alertMessage]);

  const { startJourney } = useJourney(spokeData?.processId as string, spokeData?.name as string, userId as string);

  useEffect(() => {
    if (!spokeData) return;
    startJourney();
  }, [spokeData, startJourney]);

  return (
    <>
      {alertMessage && <Alert variant={alertVariant ?? 'success'} className="mb-0 mx-12" title={alertMessage} />}
      <div
        className={classNames(
          'grid grid-cols-7 grid-rows-1 gap-4 transition-all',
          hasActiveActions(actionCenter)
            ? 'mr-2 h-[calc(100vh-75px)] overflow-hidden'
            : 'mr-4 overflow-auto sm:mr-4 md:mx-8'
        )}
      >
        <div
          className={classNames(
            'break-words p-4',
            hasActiveActions(actionCenter) ? 'col-span-4 overflow-y-scroll' : 'col-span-7 overflow-clip'
          )}
        >
          <ActionControl actionsGroup={actionCenterOptions} className="mb-6" />
          <CustomerProfileSummary userId={userId} />
          <CustomerDetails userId={userId} />
          <DirectDebitHistoryTable userId={userId}></DirectDebitHistoryTable>
        </div>
        {hasActiveActions(actionCenter) && (
          <div className="animate-slide-right col-span-3 col-start-5 overflow-y-scroll transition-all p-4">
            {isBlockAccessToRepeatOpen && (
              <BlockAccessToRepeatForm
                onSaveUserActivity={handleUserActivitySave}
                onCancel={() => dispatch(setIsBlockAccessToRepeatOpen(false))}
              />
            )}
            {isShowDebitHistoryOpen && <DirectDebitHistorySidePanel></DirectDebitHistorySidePanel>}
            {isUpdateDirectDebitAccountOpen && (
              <UpdateDDBankAccount
                loans={activeLoansWithNoDirectDebitInProgress}
                taskId={taskId}
                userId={userId}
                onFail={(err) => {
                  console.error(err);
                  setAlertMessage(err.data.message.message);
                  setAlertVariant('error');
                }}
                onSuccess={() => {
                  dispatch(setIsUpdateDirectDebitAccountOpen(false));
                  setAlertMessage('Direct debit account updated.');
                  setAlertVariant('success');
                  setSpokeData(null);
                  removeRouterParamsByNames('taskId', 'done');
                  setTimeout(() => {
                    router.reload();
                  }, 2000);
                }}
                onCancel={() => {
                  dispatch(setIsUpdateDirectDebitAccountOpen(false));
                  setSpokeData(null);
                  removeRouterParamsByNames('taskId');
                }}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};
