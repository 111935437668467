import { useEffect } from 'react';
import { useFriendlyURL, usePreQualifiedQuoteData } from '@harmoney/hooks';
import { useGetVariablesQuery, useSubmitLoanApplicationMutation, useUpdateUserProfileMutation } from '@harmoney/redux';
import {
  ArrowCircleRightIcon,
  Button,
  Card,
  Form,
  Label,
  Select,
  Spinner,
  ToggleGroup,
  useForm,
} from '@harmoney/ui-design-system';
import { relationshipStatusOptions, toggleResidencyStatusOptions } from '@harmoney/ui-utils';
import { BranchEnum as Branch, RelationshipStatusEnum, ResidencyStatus } from '@prisma/client';
import { z } from 'zod';

import { CommonProps } from '../../common-props';

import { formSchema } from './form-config';

export function GettingToKnowYou({ loanProductData, taskId, completeTask, taskFriendlyURL }: CommonProps) {
  const { branch, displayResidencyStatus } = loanProductData || {};
  const preQualifiedQuoteData = usePreQualifiedQuoteData(taskId);
  const form = useForm({
    mode: 'onTouched',
    schema: formSchema,
  });

  const {
    register,
    formState: { isSubmitting, isSubmitSuccessful },
    setValue,
  } = form;

  const { data: variables, isLoading: isVariablesLoading } = useGetVariablesQuery(taskId);
  const [submitLoanApplication] = useSubmitLoanApplicationMutation();
  const [updateUserProfile] = useUpdateUserProfileMutation();

  const handleSubmit = async (formValues: z.infer<typeof formSchema>) => {
    const loanApplication = {
      id: variables.loanApplicationId.toString(),
      residencyStatus: formValues.residencyStatus as ResidencyStatus,
      relationshipStatus: formValues.relationshipStatus as RelationshipStatusEnum,
    };

    await submitLoanApplication(loanApplication).unwrap();

    await updateUserProfile({
      data: { relationshipStatus: formValues.relationshipStatus as RelationshipStatusEnum },
    }).unwrap();

    completeTask({
      taskId,
      variables: { relationshipStatus: formValues.relationshipStatus, residencyStatus: formValues.residencyStatus },
    });
  };

  useEffect(() => {
    if (!preQualifiedQuoteData) return;
    const relationshipStatus = preQualifiedQuoteData?.relationshipStatus.toUpperCase();
    setValue('relationshipStatus', RelationshipStatusEnum[relationshipStatus]);
    setValue('residencyStatus', ResidencyStatus.AU_CITIZEN_OR_PERMANENT_RESIDENT);
  }, [preQualifiedQuoteData]);

  useFriendlyURL(taskFriendlyURL);
  if (isVariablesLoading) return <Spinner />;

  return (
    <>
      <h1>
        Let&rsquo;s <span className="text-primary">get to know you</span>
      </h1>

      <Form form={form} onSubmit={handleSubmit}>
        {displayResidencyStatus && (
          <Card>
            <Label className="mb-2">
              Are you {branch === Branch.AU ? 'an Australian' : 'a New Zealand'} citizen or permanent resident?
            </Label>
            <ToggleGroup {...register('residencyStatus')} options={toggleResidencyStatusOptions(branch)} />
          </Card>
        )}

        <Card>
          <Select
            {...register('relationshipStatus')}
            label="What is your relationship status?"
            options={relationshipStatusOptions}
          />
        </Card>

        <Button
          type="submit"
          alignIcon="end"
          icon={<ArrowCircleRightIcon size="large" />}
          variant="primary"
          isLoading={isSubmitting || isSubmitSuccessful}
          hasShadow
        >
          Continue
        </Button>
      </Form>
    </>
  );
}
