import React, { useState } from 'react';
import { useSendFeedbackMutation } from '@harmoney/redux';
import { Button, Form, IconV2 as Icon, Textarea, useForm } from '@harmoney/ui-design-system';
import classNames from 'classnames';
import { z } from 'zod';

type Props = {
  traceId: string;
  withQuestion?: boolean;
};

export const AIFeedback = ({ traceId, withQuestion = true }: Props) => {
  const [thumbsUpIcon, setThumbsUpIcon] = useState('ic:outline-thumb-up');
  const [thumbsDownIcon, setThumbsDownIcon] = useState('ic:outline-thumb-down');
  const [thumbsUpClicked, setThumbsUpClicked] = useState(false);
  const [thumbsDownClicked, setThumbsDownClicked] = useState(false);
  const [showAdditionalFeedback, setShowAdditionalFeedback] = useState(false);

  const [sendFeedback] = useSendFeedbackMutation();

  const handleFeedback = async (score: 0 | 1) => {
    if (score === 0) {
      setThumbsDownIcon('ic:baseline-thumb-down');
      setThumbsDownClicked(true);
      setShowAdditionalFeedback(true);
      setTimeout(() => setThumbsDownClicked(false), 500);
    } else {
      setThumbsUpIcon('ic:baseline-thumb-up');
      setThumbsUpClicked(true);
      await sendFeedback({ traceId, score: 1 });
      setTimeout(() => setThumbsUpClicked(false), 500);
    }
  };

  const form = useForm({
    schema: z.object({
      additionalFeedback: z.string().optional(),
    }),
    mode: 'onSubmit',
  });

  const handleNegativeFeedbackSubmit = async (values) => {
    await sendFeedback({
      traceId,
      score: 0,
      comment: values.additionalFeedback,
    });
    setShowAdditionalFeedback(false);
  };
  return (
    <div className="flex flex-col gap-2 flex-wrap">
      <div className="flex flex-row gap-4 items-center">
        {withQuestion && <span>How did the AI Analyse compare to the bank statement data?</span>}
        <div className="flex flex-row gap-2">
          <Button
            icon={
              <Icon
                icon={thumbsUpIcon}
                width={20}
                className={classNames('text-secondary transition-all', thumbsUpClicked && '-rotate-12 duration-75')}
              />
            }
            onClick={() => handleFeedback(1)}
          />
          <Button
            icon={
              <Icon
                icon={thumbsDownIcon}
                width={20}
                className={classNames('text-secondary transition-all', thumbsDownClicked && '-rotate-12 duration-75')}
              />
            }
            onClick={() => handleFeedback(0)}
          />
        </div>
      </div>
      {showAdditionalFeedback && (
        <Form form={form} onSubmit={handleNegativeFeedbackSubmit} className="flex flex-col gap-2 flex-wrap">
          <Textarea {...form.register('additionalFeedback')} placeholder="Additional feedback" rows={1} />
          <Button type="submit" variant="accent" size="small" className="min-w-fit">
            send feedback
          </Button>
        </Form>
      )}
    </div>
  );
};
