import { useState } from 'react';
import { CompleteTaskDto, FORM_KEY } from '@harmoney/api-interfaces';
import { useBankStatementRefStatus } from '@harmoney/hooks';
import { useGetBankStatementReferencesByLoanApplicationIdQuery, useGetVariablesQuery } from '@harmoney/redux';
import { Button, Card } from '@harmoney/ui-design-system';

import { CommonProps } from '../../common-props';
import { BankStatementSummaryRender } from '../BankStatementSummaryRender/BankStatementSummaryRender';

import { BankStatementSummaryAction } from './BankStatementSummaryAction';

export function BankStatementSummary({ taskId, completeTaskWithData }: CommonProps) {
  const { data: variables } = useGetVariablesQuery(taskId);
  const { data: bankStatementReferences } = useGetBankStatementReferencesByLoanApplicationIdQuery(
    variables?.loanApplicationId?.toString(),
    {
      skip: !variables,
      pollingInterval: 5000,
    }
  );

  const { allReferencesStatus } = useBankStatementRefStatus(bankStatementReferences);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleBankAdditionClick = async () => {
    await completeTaskWithData({ taskId, variables: { requestToAddBankStatement: true } });
  };

  const handleContinueClick = async () => {
    setIsSubmitting(true);
    await completeTaskWithData({ taskId, variables: { requestToAddBankStatement: false, retryBankStatement: false } });
  };

  const handleRetryClick = async (appReference: string) => {
    const completeTaskDto: CompleteTaskDto = {
      taskId,
      formKey: FORM_KEY.BANK_STATEMENT_REFERENCE_RETRIED_UPDATE,
      formData: { appReference, retried: true },
      variables: { retryBankStatement: true },
    };
    await completeTaskWithData(completeTaskDto);
  };

  return (
    <>
      <h1>
        <span className="text-primary">Add all</span> of your bank statements
      </h1>

      <Card className="!p-4">
        <p>If you use multiple banks, please connect them individually.</p>
        <div className="p-2 text-center">
          <Button size="large" variant="text" onClick={handleBankAdditionClick}>
            Add another bank
          </Button>
        </div>
      </Card>

      <BankStatementSummaryRender
        bankStatementReferences={bankStatementReferences}
        handleRetryClick={handleRetryClick}
      />

      <BankStatementSummaryAction
        referencesStatus={allReferencesStatus}
        onClick={handleContinueClick}
        isSubmitting={isSubmitting}
      />
    </>
  );
}
