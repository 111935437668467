import { forwardRef, useState } from 'react';
import { useController } from 'react-hook-form';
import type { SelectProps as RadixSelectProps } from '@radix-ui/react-select';
import * as RadixSelect from '@radix-ui/react-select';
import classNames from 'classnames';

import { CommonOptionProps } from '../../types';
import { sanitizeContent } from '../../utils';
import { useFormContext } from '../Form';
import { FormField } from '../FormField';
import { HelpText } from '../HelpText';
import { ChevronIcon } from '../Icon';
import { getIconComponent } from '../Icon/Icons/IconsWithVariant';
import { Label } from '../Label';
import { ValidationMessage } from '../ValidationMessage';

import styles from './Select.module.scss';

// TODO: Decouple Select from react-hook-form, refactoring and consolidating Select and ControlledSelect into one component
// https://harmoney.atlassian.net/browse/ENGR-7
export interface SelectProps extends Omit<RadixSelectProps, 'asChild'> {
  /**
   * The items to display in the select component.
   * @param label The label to display for the item.
   * @param value The value of the item.
   * @param imageSrc The image to display to the left of the item.
   * @param description The text to display to the bottom of the item.
   */
  options: CommonOptionProps[];
  /**
   * Select name
   */
  name: string;
  /**
   * The label to display above the select group.
   */
  label?: string;
  className?: string;
  reference?: any;
  formFieldClassName?: string;
  displayValidation?: boolean;
  placeholder?: string;
}

export const Select = forwardRef<HTMLButtonElement, SelectProps>(
  (
    {
      label,
      className,
      name,
      formFieldClassName,
      onValueChange,
      options = [],
      disabled = false,
      displayValidation = true,
      reference,
      placeholder,
      ...restProps
    }: SelectProps,
    ref
  ) => {
    const { control, formState, getFieldState } = useFormContext();
    const { isDirty, isTouched, invalid } = getFieldState(name, formState);

    // TODO: This is a hack to fix this issue https://github.com/radix-ui/primitives/issues/1658.
    // Need to remove this when the fix is merged https://github.com/radix-ui/primitives/pull/2085
    const [open, setOpen] = useState(false);

    const {
      field: { onChange, value: fieldValue, onBlur },
    } = useController({
      name,
      control,
    });

    const renderSelectedOption = (props: CommonOptionProps) => (
      <span
        key={props?.value}
        className={isDirty || invalid ? styles['selected-option'] : styles['selected-option-empty']}
      >
        {props?.imageSrc && renderIcon(props?.imageSrc, props?.width)}
        {props?.iconName && (
          <span className="mr-2 flex items-center">{getIconComponent(props?.iconName, 'secondary')}</span>
        )}
        {props?.icon && <span className="mr-2 flex items-center">{props.icon}</span>}
        <span className={fieldValue && props?.value === fieldValue ? 'text-black' : ''}>
          {props?.label && typeof props.label === 'string'
            ? sanitizeContent({
                content: props.label,
              })
            : props?.label}
        </span>
      </span>
    );

    const scrollToNearest = () => {
      const element = reference?.current;
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
    };

    const renderIcon = (imageSrc: string, width: number | string = 18) => (
      <img
        className="mr-4 flex items-center"
        src={imageSrc}
        alt="icon"
        aria-hidden={true}
        width={width}
        height={15}
        draggable={false}
      />
    );

    return (
      <div className={className}>
        {label && (
          <Label className="mb-2" aria-labelledby={label}>
            {label}
          </Label>
        )}
        <RadixSelect.Root
          onValueChange={onChange}
          value={fieldValue}
          disabled={disabled}
          open={open}
          onOpenChange={() => {
            setTimeout(() => {
              scrollToNearest();
              setOpen(!open);
            }, 10);
          }}
          {...restProps}
        >
          <RadixSelect.Trigger ref={ref} className={styles['select-trigger']} onBlur={onBlur} data-testid={name}>
            <FormField
              className={classNames(styles['form-field'], formFieldClassName)}
              isDirty={isDirty}
              isTouched={isTouched}
              isInvalid={invalid}
              disabled={disabled}
            >
              <RadixSelect.Value>
                <>
                  {options.length > 0 && fieldValue ? (
                    renderSelectedOption(
                      options.find(({ value }) => {
                        return value === fieldValue;
                      })!
                    )
                  ) : placeholder ? (
                    <span className="text-grey-3">{placeholder}</span>
                  ) : (
                    renderSelectedOption(options[0])
                  )}
                  <RadixSelect.Icon className={styles['icon']}>
                    <ChevronIcon size="tiny" />
                  </RadixSelect.Icon>
                </>
              </RadixSelect.Value>
            </FormField>
          </RadixSelect.Trigger>

          <RadixSelect.Portal>
            <RadixSelect.Content className={classNames(styles['select-content'])} position="popper" sideOffset={4}>
              {options.length > 0 &&
                options.map(
                  ({ label, value, imageSrc, iconName, description, width, icon }) =>
                    value && (
                      <RadixSelect.Item
                        className={styles['select-item']}
                        key={value}
                        value={value}
                        onTouchEnd={(e) => e.preventDefault()}
                      >
                        {imageSrc && renderIcon(imageSrc, width)}
                        {iconName && (
                          <span className="mr-[0.6875rem] flex items-center">
                            {getIconComponent(iconName, value === fieldValue ? 'secondary' : 'grey-4')}
                          </span>
                        )}
                        {icon && <span className="mr-[0.6875rem] flex items-center">{icon}</span>}
                        <span>
                          <RadixSelect.ItemText>
                            {label && typeof label === 'string'
                              ? sanitizeContent({
                                  content: label,
                                })
                              : label}
                          </RadixSelect.ItemText>

                          {description && <HelpText className="grid">{description}</HelpText>}
                        </span>
                      </RadixSelect.Item>
                    )
                )}
            </RadixSelect.Content>
          </RadixSelect.Portal>
        </RadixSelect.Root>

        {displayValidation && <ValidationMessage name={name} />}
      </div>
    );
  }
);
