import { Fragment, useEffect, useState } from 'react';
import { FORM_KEY } from '@harmoney/api-interfaces';
import { useFriendlyURL } from '@harmoney/hooks';
import {
  useCompleteTaskWithDataMutation,
  useGetEligibleDebtsForConsolidationQuery,
  useGetLoanApplicationQuery,
  useGetRefreshedLoansQuery,
  useGetVariablesQuery,
} from '@harmoney/redux';
import {
  ArrowCircleRightIcon,
  Button,
  Card,
  Divider,
  Form,
  UncontrolledCheckbox,
  useForm,
  ValidationMessage,
} from '@harmoney/ui-design-system';
import { formatCurrency, isHarmoneyDebt, LoanProductName } from '@harmoney/utilities';
import { AssetAndLiability, NetworthSource } from '@prisma/client';
import classNames from 'classnames';

import { CommonProps } from '../../common-props';
import { formSchema } from '../../debt-consolidation/ConsolidateDebt/form-config';
import { BlockSingleHmyLoanDebtConModal } from '../UpdateDebt/BlockSingleHmyLoanDebtConModal';

import { ChooseDebtItemToConsolidate } from './ChooseDebtItemToConsolidate';

export const ChooseConsolidatedDebt = ({ taskId, taskFriendlyURL }: CommonProps) => {
  useFriendlyURL(taskFriendlyURL);
  const { data: variables } = useGetVariablesQuery(taskId);
  const financialProfileId = variables?.financialProfileId as string;
  const { data: refreshedLoans } = useGetRefreshedLoansQuery(variables?.loanApplicationId?.toString(), {
    skip: !variables?.loanApplicationId,
    refetchOnMountOrArgChange: true,
  });
  const { data: debts } = useGetEligibleDebtsForConsolidationQuery(financialProfileId, {
    skip: !financialProfileId || !refreshedLoans,
  });
  const { data: loanApplicationData } = useGetLoanApplicationQuery(variables?.loanApplicationId.toString(), {
    skip: !variables,
    refetchOnMountOrArgChange: true,
  });
  const [completeTask, { isLoading }] = useCompleteTaskWithDataMutation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isBlockSingleHmyLoanDebtConModalOpen, setIsBlockSingleHmyLoanDebtConModalOpen] = useState(false);

  const minBorrowingLimit = loanApplicationData?.loanProduct?.minimumLoanAmount;
  const maxBorrowingLimit = loanApplicationData?.loanProduct?.maximumLoanAmount;

  const form = useForm({
    mode: 'onSubmit',
    schema: formSchema(minBorrowingLimit, maxBorrowingLimit, true),
    defaultValues: {
      debtsSelection: {},
    },
  });

  const {
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = form;

  const selectedDebtsArray = Object.values(watch().debtsSelection);

  const allChecked = selectedDebtsArray.every((value) => value === true);

  const indeterminate = selectedDebtsArray.some((value) => value === true) && !allChecked;

  const handleSelectAll = () => {
    if (errors?.debtsSelection) {
      clearErrors('debtsSelection');
    }
    setValue(
      'debtsSelection',
      Object.fromEntries(Object.keys(watch().debtsSelection).map((key) => [key, !allChecked]))
    );
  };

  const total =
    debts
      ?.filter((debt) => watch().debtsSelection[debt.id])
      ?.reduce((acc, curr) => {
        return acc + curr.outstandingBalance;
      }, 0) ?? 0;

  useEffect(() => {
    setValue('totalToConsolidate', total);
  }, [total, setValue]);

  const handleSubmit = async (data) => {
    setIsSubmitting(true);

    const didUserOnlySelectOneDebtToConsolidate = checkIfUserOnlySelectedOneHmyDebtToConsolidate(
      debts,
      selectedDebtsArray
    );

    if (didUserOnlySelectOneDebtToConsolidate) {
      setIsBlockSingleHmyLoanDebtConModalOpen(true);
      setIsSubmitting(false);
      return;
    }

    setIsSubmitting(false);
    await completeTask({
      taskId,
      formKey: FORM_KEY.DEBT_CONSOLIDATION_USER_SELECTION_UPDATE,
      formData: {
        debtsSelection: data.debtsSelection,
      },
      variables: { totalToConsolidate: data.totalToConsolidate },
    })
      .unwrap()
      .catch(() => {
        setIsSubmitting(false);
      });
  };

  const handleChangeLoanPurposeToPersonalLoanSubmit = async (
    loanPurposeData: {
      id: string;
      answers: {
        questionId: string;
        optionId: string;
      }[];
    }[]
  ) => {
    await completeTask({
      taskId,
      variables: {
        didLoanPurposeChange: true,
        loanProductName: LoanProductName.DEBT_CONSOLIDATION,
        loanPurposes: loanPurposeData,
      },
    });
  };

  if (!debts) {
    return null;
  }

  return (
    <div>
      <h1>
        Select your <span className="text-primary">debts to consolidate</span>
      </h1>
      <Form form={form} onSubmit={handleSubmit} className="flex flex-col gap-2 relative">
        <Card className={classNames('mt-4 flex flex-col !p-0 overflow-hidden')}>
          <div
            className={classNames(
              errors?.debtsSelection && 'border-t border-x rounded-t-xl border-error',
              'flex-row flex justify-between items-center pr-4'
            )}
          >
            <div className="flex flex-col gap-1 p-4">
              <span className="font-medium">Choose your debts</span>
            </div>
            <UncontrolledCheckbox
              name="all-debts"
              label="Select all"
              alignLabel="left"
              checked={indeterminate === true ? 'indeterminate' : allChecked}
              onCheckedChange={handleSelectAll}
            />
          </div>
          <div
            className={classNames(
              errors?.debtsSelection ? 'border border-t-grey-2 border-error' : 'border-y-grey-2 border-y'
            )}
          >
            {debts?.map((debt, index, arr) => (
              <Fragment key={debt.id}>
                <ChooseDebtItemToConsolidate debt={debt} />
                {index !== arr.length - 1 && <Divider className="text-grey-2" />}
              </Fragment>
            ))}
          </div>
          <div className="flex flex-col gap-2 p-4">
            {errors?.debtsSelection && <ValidationMessage name="debtsSelection" />}
            <div className="flex w-full flex-row justify-between">
              <span>Consolidation total</span>
              <span className="font-semibold">{total > 0 ? formatCurrency(total) : '-'}</span>
            </div>
          </div>
        </Card>
        <div className="flex flex-col gap-4">
          <Button
            alignIcon="end"
            icon={<ArrowCircleRightIcon size="large" />}
            variant="primary"
            type="submit"
            isLoading={isLoading || isSubmitting}
          >
            Continue
          </Button>
        </div>
      </Form>
      <BlockSingleHmyLoanDebtConModal
        isOpen={isBlockSingleHmyLoanDebtConModalOpen}
        closeModal={() => setIsBlockSingleHmyLoanDebtConModalOpen(false)}
        onChangeLoanPurposeSubmit={handleChangeLoanPurposeToPersonalLoanSubmit}
      />
    </div>
  );
};

function checkIfUserOnlySelectedOneHmyDebtToConsolidate(
  userDebts: Partial<AssetAndLiability> & { networthSource: Partial<NetworthSource> }[],
  selectedDebts: boolean[]
) {
  const selectedHarmoneyDebts = userDebts.filter((debt, index) => selectedDebts[index] && isHarmoneyDebt(debt));
  const selectedDebtCount = selectedDebts.filter(Boolean).length;

  if (selectedHarmoneyDebts.length === 1 && selectedDebtCount === 1) return true;

  return false;
}
