import { accountNumberInputFormat, bsbInputFormat, normalizeBsbOrAccountNumber } from '@harmoney/utilities';
import { z } from 'zod';

const BANK_ACCOUNT_ERROR = 'Please select a bank account';

const defaultValuesForUpdateDirectDebitBankAccount = {
  selectedLoans: {},
  updateDirectDebitToBankAccount: null,
  notes: '',
};

const bankAccountSchema = z
  .string({
    errorMap: (issue, _ctx) => {
      if (issue.code) return { message: BANK_ACCOUNT_ERROR };
    },
  })
  .min(1, { message: BANK_ACCOUNT_ERROR });

const formSchemaForUpdateDirectDebitBankAccount = () =>
  z.object({
    selectedLoans: z.record(z.boolean()).refine((loans) => Object.values(loans).some((isChecked) => isChecked), {
      message: 'Please select a loan',
    }),
    updateDirectDebitToBankAccount: bankAccountSchema,
    notes: z.string().min(1, { message: 'Please add a note' }),
    newBankAccount: z
      .object({
        bsbNumber: z
          .string()
          .min(6, { message: 'Please enter a valid 6-digit BSB number' })
          .refine((val) => bsbInputFormat.test(val), {
            message: 'Invalid characters. Please enter a 6 digit number.',
          })
          .transform((bsb) => {
            // Format the BSB number as xxx-xxx
            const sanitized = normalizeBsbOrAccountNumber(bsb);
            return `${sanitized.slice(0, 3)}-${sanitized.slice(3)}`;
          }),
        bsbAccountNumber: z
          .string()
          .min(4, { message: 'Please enter a 4-9 digit account number' })
          .refine((val) => accountNumberInputFormat.test(val), {
            message: 'Invalid characters. Please enter a 4-9 digit number.',
          })
          .transform((accountNumber) => {
            // Sanitize account number, remove spaces and dashes
            return normalizeBsbOrAccountNumber(accountNumber);
          }),
        hasSuppliedProofOfAccountOwnership: z.boolean().refine((val) => val, {
          message: 'Please verify the customer-provided information before updating the bank account.',
        }),
      })
      .optional(),
  });

export { defaultValuesForUpdateDirectDebitBankAccount, formSchemaForUpdateDirectDebitBankAccount };
