import type { User, UserProfile } from '@prisma/client';

export enum UpdateBankAccountEventType {
  BANK_ACCOUNT_UPDATED = 'bank_account_updated',
}

export type BankAccountDetails = {
  bankName: string;
  bankSlug: string;
  accountBsb: string;
  accountNumber: string;
  productName?: string;
  loanApplicationId?: string;
  loanId?: string;
};

export type DirectDebitHistory = {
  id: string;
  createdAt: string | Date;
  createdBy: string | (UserProfile & User);
  note: string;
  from: Array<BankAccountDetails>;
  to: BankAccountDetails;
};

export type UpdateBankAccountDetailsDto = {
  bankSlug: string;
  bankName: string;
  accountName: string;
  accountNumber: string;
  accountBsb: string;
  productName?: string;
  loanApplicationId?: string;
  loanId?: string;
};

export type UpdateBankAccountDto = {
  note: string;
  from: Array<UpdateBankAccountDetailsDto>;
  to: UpdateBankAccountDetailsDto;
};
