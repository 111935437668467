import { CompleteTaskDto, FORM_KEY } from '@harmoney/api-interfaces';
import { useBankStatementRefStatus } from '@harmoney/hooks';
import { useGetBankStatementReferencesByLoanApplicationIdQuery, useGetVariablesQuery } from '@harmoney/redux';
import { Spinner } from '@harmoney/ui-design-system';

import { CommonProps } from '../../common-props';
import { BankStatementSummaryAction } from '../BankStatementSummary/BankStatementSummaryAction';
import { BankStatementSummaryRender } from '../BankStatementSummaryRender/BankStatementSummaryRender';

export function BankStatementErrorSummary({ taskId, completeTaskWithData }: CommonProps) {
  const { data: variables } = useGetVariablesQuery(taskId);
  const { data: bankStatementReferences } = useGetBankStatementReferencesByLoanApplicationIdQuery(
    variables?.loanApplicationId?.toString(),
    {
      skip: !variables,
      pollingInterval: 5000,
    }
  );

  const { allReferencesStatus } = useBankStatementRefStatus(bankStatementReferences);

  const handleContinueClick = async () => {
    await completeTaskWithData({ taskId, variables: { retry: false } });
  };

  const handleRetryClick = async (appReference: string) => {
    const completeTaskDto: CompleteTaskDto = {
      taskId,
      formKey: FORM_KEY.BANK_STATEMENT_REFERENCE_RETRIED_UPDATE,
      formData: { appReference, retried: true },
      variables: { retry: true },
    };
    await completeTaskWithData(completeTaskDto);
  };

  if (!bankStatementReferences) {
    return <Spinner size="large" />;
  }

  return (
    <>
      {bankStatementReferences?.some(({ operations }) => operations?.[0]?.status === 'FAILURE') ? (
        <h1>
          We were <span className="text-primary">unable to connect</span> some of your bank statements
        </h1>
      ) : bankStatementReferences?.every(({ operations }) => operations?.[0]?.status === 'SUCCESS') ? (
        <h1>
          Your bank statements have been <span className="text-primary">successfully connected</span>
        </h1>
      ) : (
        <h1>
          Your bank statements are <span className="text-primary">processing</span>
        </h1>
      )}

      <BankStatementSummaryRender
        bankStatementReferences={bankStatementReferences}
        handleRetryClick={handleRetryClick}
      />

      <BankStatementSummaryAction referencesStatus={allReferencesStatus} onClick={handleContinueClick} />
    </>
  );
}
