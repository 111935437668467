import type { AccordionMultipleProps as RadixAccordionMultipleProps } from '@radix-ui/react-accordion';
import * as RadixAccordion from '@radix-ui/react-accordion';

import { sanitizeContent } from '../../utils';
import { ChevronIcon } from '../Icon';

import styles from './Accordion.module.scss';

interface AccordionItemProps {
  title: string;
  content: string | React.ReactNode;
  id?: string;
}

export interface AccordionProps extends Omit<RadixAccordionMultipleProps, 'type' | 'asChild'> {
  /**
   * The items to render in the accordion.
   */
  items: AccordionItemProps[];
  /**
   * The title on the top of the accordion.
   */
  title?: string;
  /**
   * The controlled value of the item to expand. Must be used in conjunction with `onValueChange`.
   */
  value?: string[];
  /**
   * The value of the item to expand when initially rendered. Use when you do not need to control the state of the items.
   */
  defaultValue?: string[];
  /**
   * Event handler called when the expanded state of an item changes.
   * @param value
   * @returns
   */
  onValueChange?: (value: string[]) => void;
}

export const Accordion = ({ className, items, title, value, defaultValue, ...restProps }: AccordionProps) => (
  <>
    {title && <h2 className="mb-4 text-lg font-bold">{title}</h2>}

    <RadixAccordion.Root {...restProps} type="multiple" defaultValue={defaultValue}>
      {items &&
        items.length > 0 &&
        items.map(({ title, content, id }) => (
          <RadixAccordion.Item key={title} value={title} className="border-grey-2 border-t" {...(id && { id })}>
            <RadixAccordion.Header className="my-4 text-sm font-bold">
              <RadixAccordion.Trigger className={styles['trigger']}>
                {title}
                <ChevronIcon size="tiny" />
              </RadixAccordion.Trigger>
            </RadixAccordion.Header>

            <RadixAccordion.Content className={styles['content']}>
              {typeof content === 'string' ? sanitizeContent({ content }) : content}
            </RadixAccordion.Content>
          </RadixAccordion.Item>
        ))}
    </RadixAccordion.Root>
  </>
);
