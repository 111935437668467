export const AU_FOOTER_TEXT_INFO =
  'Harmoney Australia Pty Ltd ABN 12 604 342 823, holds Australian Credit Licence Number 474726 and Australian Financial Services Licence Number 474726. Harmoney Australia Pty Ltd and Harmoney Services Australia Pty Ltd are part of the Harmoney Corp Limited group.';
export const AU_FOOTER_TEXT_DISCLAIMER =
  'This message may contain privileged and confidential information intended only for the use of the addressee named above. If you are not the intended recipient of this message you are hereby notified that any use, dissemination, distribution or reproduction of this message is prohibited. If you have received this message in error please notify Harmoney immediately. Any views expressed in this message are those of the individual sender and may not necessarily reflect the views of Harmoney.';
export const NZ_FOOTER_INFO =
  'Harmoney Services Limited (FSP593769) is registered under the Financial Service Providers (Registration and Dispute Resolution) Act 2008.';
export const NZ_FOOTER_DISCLAIMER =
  'This message may contain privileged and confidential information intended only for the use of the addressee named above. If you are not the intended recipient of this message you are hereby notified that any use, dissemination, distribution or reproduction of this message is prohibited. If you have received this message in error please notify Harmoney immediately. Any views expressed in this message are those of the individual sender and may not necessarily reflect the views of Harmoney.';

export const AU_OFFICE_ADDRESS = 'Level 17, Angel Place, 123 Pitt Street, Sydney, NSW, 2000, Australia';
export const NZ_OFFICE_ADDRESS = 'Level 3/110 Customs Street West, Auckland 1010';
