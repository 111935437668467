import React, { useMemo } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { AmountInput, Button, ControlledSelect, Label } from '@harmoney/ui-design-system';
import { CommonOptionProps } from '@harmoney/ui-design-system';
import { formatCurrency } from '@harmoney/utilities';

import { FormSchema } from '../form-config';

interface SecondaryPurposeFormProps {
  remainingAmount: number;
  availableSecondaryPurposes: CommonOptionProps[];
  maxExtraLimitForSecondaryPurpose: number;
  errorMsgForSecondaryPurposeAmount: string;
  register: UseFormRegister<FormSchema>;
  onDeleteClick: () => void;
}

export const SecondaryPurposeForm: React.FC<SecondaryPurposeFormProps> = ({
  availableSecondaryPurposes,
  maxExtraLimitForSecondaryPurpose,
  errorMsgForSecondaryPurposeAmount,
  onDeleteClick,
  register,
}: SecondaryPurposeFormProps) => {
  const loanPurposes = useMemo(() => {
    if (!availableSecondaryPurposes) {
      return [];
    }
    return [
      {
        label: 'Select loan purpose',
        value: '',
        iconName: 'more-loan-purposes',
      },
      ...availableSecondaryPurposes,
    ];
  }, [availableSecondaryPurposes]);

  return (
    <div>
      <div className="p-4">
        <fieldset className="mb-4">
          <Label className="mb-2">
            Borrow up to an extra <strong>{formatCurrency(maxExtraLimitForSecondaryPurpose)}</strong>
          </Label>
          <AmountInput {...register('secondaryPurposeAmount')} maxLength={6} displayValidation={false} />
          {errorMsgForSecondaryPurposeAmount && (
            <p role="alert" aria-live="assertive" className="leading-sm text-error mt-1 block text-sm mb-0">
              {errorMsgForSecondaryPurposeAmount}
            </p>
          )}
        </fieldset>
        <fieldset className="mb-4">
          <Label className="mb-2">What would you like to use it for?</Label>
          <ControlledSelect {...register('secondaryPurposeId')} options={loanPurposes} />
        </fieldset>
        <div className="text-right">
          <Button variant="text" onClick={onDeleteClick}>
            Delete
          </Button>
        </div>
      </div>
    </div>
  );
};
