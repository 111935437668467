import { useState } from 'react';
import { LoanApplicationDto } from '@harmoney/api-interfaces';
import { eventAnalytics, QUOTE_READY } from '@harmoney/ui-app-shell';
import { ArrowCircleRightIcon, Button, Card, ClockLightningIcon } from '@harmoney/ui-design-system';
import { formatCurrency, isPersonalLoan } from '@harmoney/utilities';

import { CommonProps } from '../../common-props';

import { TimeDisplayBySydneyTimeZone } from './QuoteReadyV2';

import 'swiper/css';

interface QuoteReadyGenericProps extends CommonProps {
  loanApplicationData: LoanApplicationDto;
}

export function QuoteReadyGeneric({
  loanApplicationData,
  loanProductData,
  taskId,
  completeTaskWithData,
}: QuoteReadyGenericProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleContinueClick = async () => {
    setIsSubmitting(true);

    await completeTaskWithData({
      taskId,
    });

    eventAnalytics.track(QUOTE_READY, {
      taskid_str: taskId,
    });
  };

  return (
    <>
      <Card>
        <h1>
          Congratulations, here&rsquo;s your <span className="text-primary">quote</span>
        </h1>
        {loanProductData && loanApplicationData && (
          <>
            <p className="mb-2">
              Fixed interest rate of{' '}
              <span className="font-medium">{loanApplicationData?.finalInterestRate as unknown as number}% p.a.</span>
            </p>
            {
              <p className="mb-0">
                You&rsquo;re approved for up to{' '}
                <span className="font-medium">{formatCurrency(loanApplicationData?.quotePresentedAmount)}</span>
              </p>
            }
          </>
        )}
      </Card>
      <Card className="flex items-center !p-4">
        <span className="mr-2 flex">
          <ClockLightningIcon />
        </span>
        <span className="text-sm">
          Hit continue and{' '}
          {isPersonalLoan(loanProductData?.name)
            ? 'the money could be in your account'
            : 'your debts could be paid off'}{' '}
          <TimeDisplayBySydneyTimeZone />
        </span>
      </Card>
      <div className="mb-6 text-center">
        <Button
          onClick={handleContinueClick}
          alignIcon="end"
          icon={<ArrowCircleRightIcon size="large" />}
          variant="primary"
          isLoading={isSubmitting}
        >
          Continue
        </Button>
      </div>
    </>
  );
}
