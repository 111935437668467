import type {
  BankAccountDto,
  DirectDebitHistory,
  MonoovaBSBValidationOutputDto,
  UpdateBankAccountDto,
} from '@harmoney/api-interfaces';

import { emptySplitApi } from './empty-api';

const updateBankAccountApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getBankAccountHistory: builder.query<DirectDebitHistory[], string>({
      query: (customerId) => ({
        url: `/update-bank-account/${customerId}/history`,
      }),
    }),
    getBankDetailsFromBsb: builder.query<MonoovaBSBValidationOutputDto, string>({
      query: (bsb) => ({
        url: `/update-bank-account/bsb/${bsb}`,
        method: 'GET',
      }),
    }),
    updateBankAccount: builder.mutation<undefined, { data: UpdateBankAccountDto; customerId: string }>({
      query: ({ customerId, data }) => ({
        url: `/update-bank-account/${customerId}`,
        body: data,
        method: 'PATCH',
      }),
    }),
    getBankAccounts: builder.query<BankAccountDto[], string>({
      query: (customerId) => ({
        url: `/update-bank-account/${customerId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetBankAccountHistoryQuery,
  useLazyGetBankDetailsFromBsbQuery,
  useUpdateBankAccountMutation,
  useGetBankAccountsQuery,
} = updateBankAccountApi;
