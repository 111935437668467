import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { SmartUIConfigType, useFriendlyURL, useInitialiseFrankieSmartUI } from '@harmoney/hooks';

import { CommonProps } from '../../common-props';

export function FrankieSmartUIOnfido({ taskId, completeTaskWithData, taskFriendlyURL }: CommonProps) {
  useInitialiseFrankieSmartUI(taskId, SmartUIConfigType.ONFIDO);

  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url) => {
      const oldPathname = window.location.pathname;
      const newPathname = url;
      const newURL = new URL(url, window.location.origin);
      // do a browser refresh when navigating from biometrics to dashboard to clean up smartui and onfido
      if (oldPathname.endsWith('/biometrics/') && (newPathname === '/' || newPathname === '/dashboard/')) {
        window.location.href = newURL.href;
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router]);

  useEffect(() => {
    const handleExternalIDVCheckCompleted = (e) => {
      setTimeout(() => completeTaskWithData({ taskId }), 3000);
    };

    window.addEventListener('FF_EXTERNAL_IDV_CHECK_COMPLETED', handleExternalIDVCheckCompleted);

    return () => {
      window.removeEventListener('FF_EXTERNAL_IDV_CHECK_COMPLETED', handleExternalIDVCheckCompleted);
    };
  }, [completeTaskWithData, taskId]);

  useFriendlyURL(taskFriendlyURL);

  return (
    <>
      <ff-onboarding-widget width="576px" height="600px" />
    </>
  );
}
