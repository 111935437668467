import { useCallback, useState } from 'react';
import { LoanPurposeQuestionDto } from '@harmoney/api-interfaces';
import { useFriendlyURL, useTransformLoanPurposes } from '@harmoney/hooks';
import {
  useGetLoanPurposesQuery,
  useGetUserQuery,
  useGetVariablesQuery,
  useSubmitLoanApplicationMutation,
  useUpdateLoanPurposesMutation,
} from '@harmoney/redux';
import {
  AmountInput,
  ArrowCircleRightIcon,
  Button,
  Card,
  Checkbox,
  Form,
  Label,
  RadioSelectSelector,
  ToggleGroup,
  useForm,
} from '@harmoney/ui-design-system';
import { AmountFormatter } from '@harmoney/ui-utils';
import { LoanPurposeQuestionType } from '@prisma/client';
import { z } from 'zod';

import { CommonProps } from '../../common-props';

import { createFormSchema, defaultValues } from './form-config';

/**
 * @deprecated This component is deprecated and will be removed
 * when there are no active process instances using it.
 */
export function LoanPurposeV2({ loanProductData, taskId, completeTask, taskFriendlyURL }: CommonProps) {
  const { id: loanProductId, minimumLoanAmount, maximumLoanAmount } = loanProductData || {};

  const formSchema = createFormSchema(minimumLoanAmount, maximumLoanAmount);
  const form = useForm({
    mode: 'onTouched',
    schema: formSchema,
    defaultValues,
  });
  const {
    watch,
    resetField,
    register,
    formState: { isSubmitting, isSubmitSuccessful },
  } = form;
  const watchLoanPurpose = watch('loanPurpose');

  const [questions, setQuestions] = useState<LoanPurposeQuestionDto[]>([]);
  const { data: userData } = useGetUserQuery();
  const { data: loanPurposes, isLoading: isLoanPurposesLoading } = useGetLoanPurposesQuery(loanProductId, {
    skip: !loanProductId,
  });
  const { primaryLoanPurposes, secondaryLoanPurposes } = useTransformLoanPurposes(loanPurposes);
  const { data: variables } = useGetVariablesQuery(taskId);
  const [updateLoanPurposes] = useUpdateLoanPurposesMutation();
  const [submitLoanApplication] = useSubmitLoanApplicationMutation();

  const handleSelectedLoanPurpose = useCallback(() => {
    if (!watchLoanPurpose.primary) return;
    const { questions } = loanPurposes.find((loanPurpose) => loanPurpose.id === watchLoanPurpose.primary);
    if (questions.length > 0) {
      setQuestions(questions);
    } else {
      setQuestions([]);
      resetField('questions', { defaultValue: [] });
    }
  }, [loanPurposes, resetField, watchLoanPurpose.primary]);

  const handleSubmit = async (data: z.infer<typeof formSchema>) => {
    const loanPurposeId = watchLoanPurpose.primary;
    const applicationId = variables.loanApplicationId.toString();

    const loanPurposeData = [
      {
        id: loanPurposeId,
        answers: data.questions.map(({ optionId }, index) => ({
          questionId: questions[index].id,
          optionId: typeof optionId === 'boolean' ? null : optionId,
        })),
        requestedAmount: data.amount,
      },
    ];

    await updateLoanPurposes({ applicationId, loanPurposes: loanPurposeData });
    await submitLoanApplication({ id: applicationId, requestedAmount: data.amount });

    completeTask({ taskId, variables: { requestedAmount: data.amount } });
  };

  useFriendlyURL(taskFriendlyURL);

  return (
    <>
      <h1>
        Welcome <span className="text-primary">{userData?.preferredName ?? ''}</span>
      </h1>

      <Form form={form} onSubmit={handleSubmit}>
        {!isLoanPurposesLoading && primaryLoanPurposes.length > 0 && secondaryLoanPurposes.length > 0 && (
          <Card>
            <Label className="mb-2">Select a loan purpose</Label>
            <RadioSelectSelector
              name="loanPurpose"
              radioOptions={primaryLoanPurposes}
              selectOptions={secondaryLoanPurposes}
              onChange={handleSelectedLoanPurpose}
            />
          </Card>
        )}

        {questions.length > 0 &&
          questions.map(({ id, questionType, name, options }, index) => (
            <Card key={id}>
              {questionType === LoanPurposeQuestionType.YESNO && (
                <>
                  <Label className="mb-2">{name}</Label>
                  <ToggleGroup
                    name={`questions.${index}.optionId`}
                    options={options.map((option) => ({ label: option.name, value: option.id }))}
                  />
                </>
              )}
              {questionType === LoanPurposeQuestionType.CHECK && (
                <Checkbox name={`questions.${index}.optionId`} label={name} />
              )}
            </Card>
          ))}

        <Card>
          <Label className="mb-2">How much would you like to borrow?</Label>
          <AmountInput
            {...register('amount')}
            maxLength={6}
            validationMessage={`An amount between ${AmountFormatter.format(
              minimumLoanAmount
            )} and ${AmountFormatter.format(maximumLoanAmount)}`}
          />
        </Card>

        <Button
          type="submit"
          alignIcon="end"
          icon={<ArrowCircleRightIcon size="large" />}
          variant="primary"
          isLoading={isSubmitting || isSubmitSuccessful}
          hasShadow
        >
          Continue
        </Button>
      </Form>
    </>
  );
}
