import { DebtConsolidationLiabilityDisbursementValidationResponse } from '@harmoney/api-interfaces';
import { AssetAndLiability, NetworthSource } from '@prisma/client';
import { Prisma } from '@prisma/client';

import { emptySplitApi } from './empty-api';

const debtPaymentApi = emptySplitApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getPostApprovalConsolidatedDebts: builder.query<
      (AssetAndLiability & { networthSource: NetworthSource; networthSourceName: string })[],
      void
    >({
      query: () => ({
        url: `/financial-profile/liabilities/post-approval-consolidated-debts`,
        method: 'GET',
      }),
    }),
    validateDisbursements: builder.mutation<
      DebtConsolidationLiabilityDisbursementValidationResponse,
      Prisma.DisbursementCreateManyInput[]
    >({
      query: (data) => ({
        url: `/disbursement/validation`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { useGetPostApprovalConsolidatedDebtsQuery, useValidateDisbursementsMutation } = debtPaymentApi;
