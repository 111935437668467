import Image from 'next/image';
import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { BankAccountDto, VaultLoanViewDto } from '@harmoney/api-interfaces';
import { LoanApplicationQueryResponse } from '@harmoney/redux';
import { Checkbox, Label, ValidationMessage } from '@harmoney/ui-design-system';
import { Icon } from '@iconify/react';
import { z } from 'zod';

import { maskNormalizedBankAccount } from '../../../../fulfilment/Payment/utils';
import { formSchemaForUpdateDirectDebitBankAccount } from '../form-config';

type FormValues = z.infer<ReturnType<typeof formSchemaForUpdateDirectDebitBankAccount>>;

interface LoanSelectorProps {
  loans: (VaultLoanViewDto & LoanApplicationQueryResponse)[];
  register: UseFormRegister<FormValues>;
  loanBankAccountMap: Record<string, BankAccountDto>;
}

const LoanSelector = ({ loans, register, loanBankAccountMap }: LoanSelectorProps) => {
  return (
    <div>
      <div>
        <Label className="mb-2">Select the loans you want to update the direct debit account for?</Label>
      </div>
      <div className="flex flex-col space-y-2">
        {loans.map((loan) => {
          const primaryAccount = loanBankAccountMap[loan.businessKey];

          return (
            <div className="flex p-4 rounded-lg border border-grey-2 bg-white" key={loan.businessKey}>
              <div className="flex space-x-2">
                <Checkbox
                  name={`selectedLoans.${loan.businessKey}`}
                  value={`selectedLoans.${loan.businessKey}`}
                  {...register(`selectedLoans.${loan.businessKey}`)}
                />
                <div className="flex flex-col text-grey-5">
                  <span className="font-medium text-base leading-[20px]">{loan?.loanInformation?.loanPurpose}</span>
                  <span className="font-normal text-xs leading-[18px]">{loan?.businessKey}</span>
                </div>
              </div>
              <div className="flex-grow"></div>
              {primaryAccount && (
                <div className="flex space-x-2">
                  {primaryAccount.bankSlug ? (
                    <Image
                      src={primaryAccount.bankLogoUrl}
                      alt={`${primaryAccount.bankName || 'Bank'} logo`}
                      className="h-[28px]"
                      aria-hidden="true"
                      draggable="false"
                      height={25}
                      width={80}
                    />
                  ) : (
                    <Icon icon="mdi:bank" fontSize="2rem" />
                  )}
                  <div className="flex flex-col text-grey-5">
                    <span className="font-normal text-base max-w-20">{primaryAccount.bankName}</span>
                    <span className="text-xs">
                      {primaryAccount
                        ? maskNormalizedBankAccount(primaryAccount.bsb, primaryAccount.accountNumber)
                        : 'No bank account available'}
                    </span>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <ValidationMessage name="selectedLoans" />
    </div>
  );
};

export default LoanSelector;
