import { AssetAndLiability, NetworthSource } from '@prisma/client';
import { NetworthSourceEnum } from '../financial-profile';
import { DebtConsolidationDisbursementErrorStatus } from './debt-consolidation-disbursement-error-status.enum';

export interface DebtConsolidationLiabilityDto {
  liability: Partial<AssetAndLiability & { networthSource: Partial<NetworthSource> }>;
  isEligibleForLowerInterest: boolean;
  isEligibleForPaylessMonthly: boolean;
  isEligibleForDebtCombination: boolean;
}

export const EligibleDebts = [
  NetworthSourceEnum.LIABILITY_CREDIT_CARD_ID,
  NetworthSourceEnum.LIABILITY_PERSONAL_LOAN_ID,
  NetworthSourceEnum.LIABILITY_OVERDRAFT_ID,
  NetworthSourceEnum.LIABILITY_CAR_LOAN_ID,
  NetworthSourceEnum.LIABILITY_LINE_OF_CREDIT_ID,
];

export type DebtConsolidationInvalidLiabilityDto = {
  liabilityId: string;
  billerCode?: string;
  bsb?: string;
  accountNumber?: string;
  reference?: string;
  error?: string;
  status: DebtConsolidationDisbursementErrorStatus;
};

export type DebtConsolidationLiabilityDisbursementValidationResponse = {
  success: boolean;
  failedVerifications: DebtConsolidationInvalidLiabilityDto[];
};
