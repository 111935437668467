import { useState } from 'react';

export function useFormSubmission<T>() {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleFormSubmit = (submitFunction: (data: T) => Promise<void>) => {
    return async (data: T) => {
      setIsSubmitting(true);
      try {
        await submitFunction(data);
      } catch (error) {
        // Centralized error handling (e.g., toast notifications)
        console.error('Submission failed:', error);
        setIsSubmitting(false);
      }
    };
  };

  return { isSubmitting, handleFormSubmit };
}
