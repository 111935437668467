import { NetworthSourceEnum, ResidentialStatusEnum } from '@harmoney/api-interfaces';
import { isPartnered, stringToBool } from '@harmoney/ui-utils';
import { IncomeAndExpenseFrequencyEnum, RelationshipStatusEnum } from '@prisma/client';

export const getLabelForLivingType = (livingType: ResidentialStatusEnum) => {
  const defaultLabel = {
    label: 'the cost for your accommodation',
    labelForTotalAmount: 'Total cost',
  };

  const labelsForLivingType: Record<ResidentialStatusEnum | string, { label: string; labelForTotalAmount: string }> = {
    [ResidentialStatusEnum.LIVE_WITH_PARENTS]: defaultLabel,
    [ResidentialStatusEnum.GOVERNMENT_HOUSING]: defaultLabel,
    [ResidentialStatusEnum.COMPANY_HOUSING]: defaultLabel,
    [ResidentialStatusEnum.RENTING]: {
      label: 'your rent',
      labelForTotalAmount: 'Total rent',
    },
    [ResidentialStatusEnum.BOARDER]: {
      label: 'the cost for your board',
      labelForTotalAmount: 'Total board',
    },
  };

  return labelsForLivingType[livingType] || labelsForLivingType.defaultLabel;
};

export const getErrorMessageForSharedExpense = (livingType: ResidentialStatusEnum) =>
  `Please check the amount entered. Your share must be lower than the ${getLabelForLivingType(
    livingType
  ).labelForTotalAmount.toLowerCase()}.`;

export const getNetWorthSourceId = (livingType: ResidentialStatusEnum) => {
  switch (livingType) {
    case ResidentialStatusEnum.RENTING:
      return NetworthSourceEnum.EXPENSE_RENT_ID;
    case ResidentialStatusEnum.BOARDER:
      return NetworthSourceEnum.EXPENSE_BOARDER_ID;
    case ResidentialStatusEnum.LIVE_WITH_PARENTS:
      return NetworthSourceEnum.EXPENSE_LIVE_WITH_PARENTS_ID;
    case ResidentialStatusEnum.GOVERNMENT_HOUSING:
      return NetworthSourceEnum.EXPENSE_GOVERNMENT_HOUSING_ID;
    case ResidentialStatusEnum.COMPANY_HOUSING:
      return NetworthSourceEnum.EXPENSE_COMPANY_HOUSING_ID;
  }
};

export const shouldDisplaySharedExpense = (relationshipStatus: RelationshipStatusEnum) =>
  isPartnered(relationshipStatus);

export const buildChildSupportIncomeDto = (prefilledData, data) => {
  const id = prefilledData?.childSupport?.childSupportIncomeId || undefined;

  if (stringToBool(data?.childSupport?.hasChildSupportReceived)) {
    return [
      {
        id,
        networthSourceId: NetworthSourceEnum.INCOME_CHILD_SUPPORT_ID,
        declaredAmount: data?.childSupport?.childSupportReceivedAmount,
        frequency: data?.childSupport?.childSupportReceivedFrequency as IncomeAndExpenseFrequencyEnum,
      },
    ];
  }

  return [
    {
      id,
      needDelete: true,
      networthSourceId: NetworthSourceEnum.INCOME_CHILD_SUPPORT_ID,
    },
  ];
};
