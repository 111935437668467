import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { FORM_KEY, NetworthSourceEnum } from '@harmoney/api-interfaces';
import {
  FINANCIAL_CONFIRMATION_SECTION_LIST,
  FinancialConfirmationSectionEnum,
  useBankStatementRefStatus,
  useFinancialConfirm,
  useFriendlyURL,
} from '@harmoney/hooks';
import {
  useGetBankStatementReferencesByLoanApplicationIdQuery,
  useGetCreditFileLiabilitiesQuery,
  useGetFinancialSummaryQuery,
  useGetLoanProductByTaskIdQuery,
  useGetRefreshedLoansQuery,
  useGetUserProfileQuery,
  useGetVariablesQuery,
} from '@harmoney/redux';
import {
  AmountFrequency,
  ArrowCircleRightIcon,
  Button,
  Card,
  Dialog,
  Form,
  QuoteLoading,
  Spinner,
  ToggleGroup,
  useForm,
} from '@harmoney/ui-design-system';
import { frequencyOptions, isPartnered, stringToBool, toggleYesNoOptions } from '@harmoney/ui-utils';
import { isDebtCon } from '@harmoney/utilities';
import { useScrollIntoView } from '@mantine/hooks';

import { CommonProps } from '../../common-props';

import * as FinancialConfirmationSections from './components';
import { createFormSchemaForFinancialConfirmation } from './form-config';

export function FinancialConfirmation({ taskId, completeTaskWithData, taskFriendlyURL }: CommonProps) {
  useFriendlyURL(taskFriendlyURL);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showBankStatementDialog, setShowBankStatementDialog] = useState(false);
  const { scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>({});

  const {
    confirmedSections,
    confirmSection,
    localPartnerIncome,
    setLocalPartnerIncome,
    timestampTriggeringScrollIntoView,
  } = useFinancialConfirm();
  const router = useRouter();

  const { data: variables } = useGetVariablesQuery(taskId, { refetchOnMountOrArgChange: true });
  const { data: bankStatementReferences } = useGetBankStatementReferencesByLoanApplicationIdQuery(
    variables?.loanApplicationId?.toString(),
    {
      skip: !variables,
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
    }
  );
  const { data: loanProductData } = useGetLoanProductByTaskIdQuery(taskId as string, {
    skip: !taskId,
    refetchOnMountOrArgChange: true,
  });
  const { data: refreshedLoans, isLoading } = useGetRefreshedLoansQuery(variables?.loanApplicationId?.toString(), {
    skip: !variables?.loanApplicationId || !isDebtCon(loanProductData?.name),
    refetchOnMountOrArgChange: true,
  });
  const { data: creditFileLiabilities } = useGetCreditFileLiabilitiesQuery(variables?.financialProfileId as string, {
    skip: !variables?.financialProfileId || (!refreshedLoans && isDebtCon(loanProductData?.name)),
  });
  const { data: userProfileData } = useGetUserProfileQuery(null, { refetchOnMountOrArgChange: true });
  const { data: financialSummary, refetch: refetchFinancialSummary } = useGetFinancialSummaryQuery(
    { id: variables?.financialProfileId.toString(), loanApplicationId: variables?.loanApplicationId?.toString() },
    {
      skip: !variables || (!refreshedLoans && isDebtCon(loanProductData?.name)),
      refetchOnMountOrArgChange: true,
    }
  );

  const shouldDisplayPartnerIncomeSection = useMemo(() => {
    let shouldDisplayPartnerIncomeSection = false;
    if (
      isPartnered(userProfileData?.relationshipStatus) &&
      financialSummary?.incomes?.findIndex(
        (income) => income.networthSourceId === NetworthSourceEnum.INCOME_PARTNER_ID
      ) === -1
    ) {
      shouldDisplayPartnerIncomeSection = true;
    }
    return shouldDisplayPartnerIncomeSection;
  }, [financialSummary, userProfileData]);
  const { allReferencesStatus } = useBankStatementRefStatus(bankStatementReferences);

  const formSchema = createFormSchemaForFinancialConfirmation(shouldDisplayPartnerIncomeSection);
  const form = useForm({
    mode: 'onTouched',
    schema: formSchema,
    defaultValues: {
      hasExpenseChange: '',
      hasIncomeChange: '',
      incomeDeclaredAmount: null,
      expenseDeclaredAmount: null,
      incomeFrequency: '',
      expenseFrequency: '',
    },
  });
  const { register, watch, reset } = form;
  const watchForm = watch();

  const hasSelfEmployedIncome = useMemo(() => {
    return financialSummary?.incomes?.some(
      (income) => income.networthSourceId === NetworthSourceEnum.INCOME_SELF_EMPLOYED_ID
    );
  }, [financialSummary]);

  const handleSubmit = async (data) => {
    try {
      let incomeChangeAmount, expenseChangeAmount, incomeChangeFrequency, expenseChangeFrequency;
      if (data.hasIncomeChange === 'Yes') {
        incomeChangeAmount = data.incomeDeclaredAmount;
        incomeChangeFrequency = data.incomeFrequency;
      } else {
        incomeChangeAmount = 0;
        incomeChangeFrequency = null;
      }

      if (data.hasExpenseChange === 'Yes') {
        expenseChangeAmount = data.expenseDeclaredAmount;
        expenseChangeFrequency = data.expenseFrequency;
      } else {
        expenseChangeAmount = 0;
        expenseChangeFrequency = null;
      }

      const incomeData = [];
      incomeData.push({
        networthSourceId: NetworthSourceEnum.INCOME_CHANGE_ID,
        declaredAmount: incomeChangeAmount,
        frequency: incomeChangeFrequency,
        expectIncreaseDecreaseChange: stringToBool(data.hasIncomeChange),
      });
      const expenseData = {
        networthSourceId: NetworthSourceEnum.EXPENSE_CHANGE_ID,
        declaredAmount: expenseChangeAmount,
        frequency: expenseChangeFrequency,
        expectIncreaseDecreaseChange: stringToBool(data.hasExpenseChange),
      };
      if (shouldDisplayPartnerIncomeSection) {
        const hasPartnerIncome = stringToBool(data.hasPartnerIncome);
        const frequency = data.partnerIncomeAmount && data.partnerIncomeAmount > 0 ? data.partnerIncomeFrequency : null;
        incomeData.push({
          networthSourceId: NetworthSourceEnum.INCOME_PARTNER_ID,
          declaredAmount: hasPartnerIncome ? data.partnerIncomeAmount : null,
          frequency: hasPartnerIncome ? frequency : null,
          hasPartnerIncome,
          payType: hasPartnerIncome ? data.partnerIncomePayType : null,
        });
      }
      setIsSubmitting(true);

      const completeTaskDto = {
        taskId,
        variables: { nextStep: 'Continue', hasSelfEmployedIncome },
        formData: {
          incomes: incomeData,
          expenses: [expenseData],
        },
        formKey: FORM_KEY.INCOME_AND_EXPENSE_UPDATE,
      };
      await completeTaskWithData(completeTaskDto);
    } catch (error) {
      console.error(`Error submitting financial confirmation [ taskId: ${taskId} ] [ error: ${error} ]`);
      setIsSubmitting(false);
    }
  };

  const handleUpdate = async (nextStep: FinancialConfirmationSectionEnum) => {
    if (shouldDisplayPartnerIncomeSection) {
      setLocalPartnerIncome({
        hasPartnerIncome: watchForm?.hasPartnerIncome,
        partnerIncomeAmount: watchForm?.partnerIncomeAmount,
        partnerIncomeFrequency: watchForm?.partnerIncomeFrequency,
        partnerIncomePayType: watchForm?.partnerIncomePayType,
      });
    }
    await completeTaskWithData({ taskId, variables: { nextStep } });
  };

  const [isNextSectionIncomeChange, isNextSectionExpenseChange, confirmedAllSections] = useMemo(() => {
    if (!confirmedSections) return [false, false, false];
    return [
      FINANCIAL_CONFIRMATION_SECTION_LIST[confirmedSections.length] === FinancialConfirmationSectionEnum.IncomeChange,
      FINANCIAL_CONFIRMATION_SECTION_LIST[confirmedSections.length] == FinancialConfirmationSectionEnum.ExpenseChange,
      confirmedSections.length === FINANCIAL_CONFIRMATION_SECTION_LIST.length,
    ];
  }, [confirmedSections?.length]);

  useEffect(() => {
    if (watchForm?.hasIncomeChange && !confirmedSections.includes(FinancialConfirmationSectionEnum.IncomeChange)) {
      confirmSection(FinancialConfirmationSectionEnum.IncomeChange);
    }
    if (watchForm?.hasExpenseChange && !confirmedSections.includes(FinancialConfirmationSectionEnum.ExpenseChange)) {
      confirmSection(FinancialConfirmationSectionEnum.ExpenseChange);
    }
  }, [watchForm?.hasIncomeChange, watchForm?.hasExpenseChange, confirmSection, confirmedSections]);

  useEffect(() => {
    if (targetRef?.current && confirmedSections?.length !== 1 && !confirmedAllSections) {
      scrollIntoView();
    }
  }, [
    confirmedSections?.length,
    scrollIntoView,
    targetRef?.current,
    confirmedAllSections,
    timestampTriggeringScrollIntoView,
  ]);

  useEffect(() => {
    if (shouldDisplayPartnerIncomeSection) {
      reset({
        hasPartnerIncome: localPartnerIncome.hasPartnerIncome,
        partnerIncomePayType: localPartnerIncome.partnerIncomePayType as 'NET' | 'GROSS',
        partnerIncomeAmount: localPartnerIncome.partnerIncomeAmount,
        partnerIncomeFrequency: localPartnerIncome.partnerIncomeFrequency,
      });
    }
  }, [shouldDisplayPartnerIncomeSection, localPartnerIncome]);

  useEffect(() => {
    if (refreshedLoans?.hasChanged && financialSummary && !isLoading) {
      refetchFinancialSummary();
    }
  }, [financialSummary, refetchFinancialSummary, refreshedLoans, isLoading]);

  if (!variables) return <Spinner />;

  return (
    <Form form={form} onSubmit={handleSubmit}>
      {isSubmitting && isDebtCon(loanProductData?.name) ? (
        <QuoteLoading />
      ) : (
        <>
          <h1>
            <strong className="text-primary">Are we still up to date?</strong> You can update it if needed!
          </h1>
          {FINANCIAL_CONFIRMATION_SECTION_LIST.slice(0, confirmedSections.length + 1).map((section) => {
            const Section = FinancialConfirmationSections[section];
            if (!Section) return null;
            return (
              <div
                key={section}
                ref={section === FINANCIAL_CONFIRMATION_SECTION_LIST[confirmedSections.length] ? targetRef : null}
              >
                <Section
                  onUpdate={handleUpdate}
                  confirmedSections={confirmedSections}
                  financialSummary={financialSummary}
                  creditFileLiabilities={creditFileLiabilities}
                  isDebtCon={isDebtCon(loanProductData?.name)}
                  onConfirmSection={confirmSection}
                  shouldDisplayPartnerIncomeSection={shouldDisplayPartnerIncomeSection}
                  form={form}
                />
              </div>
            );
          })}

          {(isNextSectionIncomeChange || isNextSectionExpenseChange || confirmedAllSections) && (
            <Card>
              <ToggleGroup
                {...register('hasIncomeChange')}
                key={`hasIncomeChange`}
                label="Do you anticipate your income to significantly decrease in a way that might affect your ability to make repayments to us?"
                options={toggleYesNoOptions}
                ref={isNextSectionIncomeChange ? targetRef : null}
              />

              {watchForm?.hasIncomeChange === 'Yes' && (
                <AmountFrequency
                  register={register}
                  label={`By how much will your income decrease?`}
                  inputKey="incomeDeclaredAmount"
                  selectKey="incomeFrequency"
                  name="income-changes"
                  options={frequencyOptions}
                  className="mt-4"
                />
              )}
            </Card>
          )}
          {(isNextSectionExpenseChange || confirmedAllSections) && (
            <Card>
              <ToggleGroup
                {...register('hasExpenseChange')}
                key={`hasExpenseChange`}
                label="Do you anticipate your expenses to significantly increase in a way that might affect your ability to make repayments to us?"
                options={toggleYesNoOptions}
                ref={isNextSectionExpenseChange ? targetRef : null}
              />

              {watchForm?.hasExpenseChange === 'Yes' && (
                <AmountFrequency
                  register={register}
                  label={`By how much will your expenses increase?`}
                  inputKey="expenseDeclaredAmount"
                  selectKey="expenseFrequency"
                  name="expense-changes"
                  options={frequencyOptions}
                  className="mt-4"
                />
              )}
            </Card>
          )}
          {confirmedAllSections && (
            <div className="flex justify-center">
              {['success', 'partial-success'].includes(allReferencesStatus) ? (
                <Button
                  type="submit"
                  alignIcon="end"
                  variant="primary"
                  icon={<ArrowCircleRightIcon size="large" />}
                  hasShadow
                  isLoading={isSubmitting}
                >
                  Continue
                </Button>
              ) : (
                <Button
                  type="button"
                  alignIcon="end"
                  variant="primary"
                  icon={<ArrowCircleRightIcon size="large" />}
                  hasShadow
                  onClick={() => setShowBankStatementDialog(true)}
                >
                  Continue
                </Button>
              )}
            </div>
          )}

          <Dialog
            open={showBankStatementDialog}
            title="Processing bank statements..."
            defaultOpen={false}
            onOpenChange={setShowBankStatementDialog}
            modal
          >
            <div className="flex flex-col p-4 gap-2">
              <p>About 2-3 minutes remaining.</p>
              <p>Processing bank statements is taking longer than expected. Please wait or come back later.</p>
              <Button
                type="button"
                alignIcon="end"
                variant="primary"
                className="mb-4 sm:mb-0 self-center"
                icon={<ArrowCircleRightIcon size="large" />}
                hasShadow
                onClick={() => router.reload()}
              >
                Refresh
              </Button>
            </div>
          </Dialog>
        </>
      )}
    </Form>
  );
}
