import {
  BankStatementFilesDto,
  BankStatementProviderConfigurationDto,
  UndisclosedDebtDto,
} from '@harmoney/api-interfaces';

import { emptySplitApi } from './empty-api';

const bankStatementApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getBankStatementConfiguration: builder.query<BankStatementProviderConfigurationDto, { applicationId: string }>({
      query: ({ applicationId }) => ({
        url: '/bank-statement/configuration',
        method: 'GET',
        params: {
          applicationId,
        },
      }),
    }),
    updateBankStatementBankNameAndStatus: builder.mutation<
      boolean,
      { appReference: string; status: string; bankName: string }
    >({
      query: ({ appReference, status, bankName }) => ({
        url: `/bank-statement/${appReference}`,
        body: { bankName, status },
        method: 'PATCH',
      }),
      invalidatesTags: ['BankStatementReference'],
    }),
    getBankStatementOperations: builder.query<BankStatementFilesDto[], { userId: string; applicationId: string }>({
      query: ({ userId, applicationId }) => ({
        url: 'bank-statement/operations',
        method: 'GET',
        params: {
          userId,
          applicationId,
        },
      }),
    }),
    getBankStatementFileUrl: builder.query<string, string>({
      query: (fileName) => ({
        url: '/bank-statement/file',
        method: 'GET',
        params: {
          fileName: encodeURIComponent(fileName),
        },
      }),
    }),
    /**
     * @deprecated This endpoint is deprecated and could be removed after 01/01/2025
     */
    retryBankStatementReference: builder.mutation<boolean, { appReference: string }>({
      query: ({ appReference }) => ({
        url: `/bank-statement/retry`,
        method: 'POST',
        body: { appReference },
      }),
      invalidatesTags: ['BankStatementReference'],
    }),
    getUndisclosedDebts: builder.query<UndisclosedDebtDto[], string>({
      query: (loanApplicationId) => ({
        url: `/bank-statement/undisclosed-debts`,
        method: 'GET',
        params: {
          loanApplicationId,
        },
      }),
    }),
  }),
});

export const {
  useGetBankStatementConfigurationQuery,
  useUpdateBankStatementBankNameAndStatusMutation,
  useGetBankStatementOperationsQuery,
  useLazyGetBankStatementFileUrlQuery,
  useRetryBankStatementReferenceMutation,
  useGetUndisclosedDebtsQuery,
} = bankStatementApi;
