import { BranchEnum } from '@prisma/client';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import moment from 'moment';

dayjs.extend(utc);
dayjs.extend(timezone);

export enum TimeZoneEnum {
  UTC = 'UTC',
  AUSTRALIA_SYDNEY = 'Australia/Sydney',
  NEW_ZEALAND_AUCKLAND = 'Pacific/Auckland',
}

export const branchToTimezoneMap: Record<BranchEnum, TimeZoneEnum> = {
  [BranchEnum.AU]: TimeZoneEnum.AUSTRALIA_SYDNEY,
  [BranchEnum.NZ]: TimeZoneEnum.NEW_ZEALAND_AUCKLAND,
};

export enum DateFormatEnum {
  ISO = 'YYYY-MM-DDTHH:mm:ssZ',
  DATE_TIME = 'YYYY-MM-DD HH:mm:ss',
  ISO_WITHOUT_TZ_OFFSET = 'YYYY-MM-DDTHH:mm:ss',
  DATE = 'YYYY-MM-DD',
  YYYYMMDDHHmmss = 'YYYYMMDDHHmmss',
  ISO_TZ_OFFSET = 'YYYY-MM-DDTHH:mm:ss+hh:mm',
}
/**
 *Check if the given date is valid.
 * @param date
 */
export const checkValidDate = (date: string | Date) => {
  if (!date || !dayjs(date).isValid()) {
    throw new Error('Invalid Date');
  }
};

/**
 * Format the given date to the given format.
 * @param date - The date to be formatted.
 * @param format - The format to be used.
 * @returns The formatted date.
 */
export const formatDate = (date: Date | string, format: DateFormatEnum, timeZone?: TimeZoneEnum): string => {
  checkValidDate(date);
  if (timeZone) {
    return dayjs(date).tz(timeZone).format(format);
  }
  return dayjs(date)?.format(format);
};

/**
 * Get the current date in the given timezone.
 * @param timezone - The timezone to be used.
 * @returns The current date in the given timezone.
 * @throws Error if the timezone is invalid.
 */
export const getCurrentDateInTimezone = (timezone: TimeZoneEnum): Date => {
  return dayjs().tz(timezone).toDate();
};

/**
 * Get the  date in the given timezone.
 * @param timezone - The timezone to be used.
 * @returns The current date in the given timezone.
 * @throws Error if the timezone is invalid.
 */
export const getDateInTimezone = (date: Date, timezone: TimeZoneEnum): Date => {
  return dayjs(date).tz(timezone).toDate();
};

/**
 * Get the Day.js date object in the timezone based on the branch.
 * This returns a Day.js object, allowing you to use Day.js functions like `.format()`.
 * This function first convert any date to UTC first (if it's not already in UTC), and then convert that UTC date to the branch-specific timezone (either Sydney for AU or Auckland for NZ).
 * @param branch - The branch to determine the timezone (AU for Sydney, NZ for Auckland).
 * @param date - The date to be converted. Defaults to the current date if not provided.
 * @returns A Day.js object representing the date in the timezone corresponding to the branch.
 * @throws Error if the branch is invalid.
 */
export const getDayjsInTimezoneByBranch = (branch: BranchEnum, date?: string | Date) => {
  const timeZone = branchToTimezoneMap[branch];

  if (!timeZone) {
    throw new Error(`Invalid branch specified: ${branch}`);
  }

  return dayjs.utc(date || dayjs()).tz(timeZone);
};

/**
 * Get the  date in the given timezone.
 * @param timezone - The timezone to be used.
 * @returns The current date in the given timezone.
 * @throws Error if the timezone is invalid.
 */
export const getUnixDateInTimezone = (date: number, timezone: TimeZoneEnum): Date => {
  return dayjs.unix(date).tz(timezone).toDate();
};

/**
 * Convert the given date to Unix timestamp.
 * @param date - The date to be converted.
 * @returns The Unix timestamp for the given date.
 */
export const convertDateToUnixTime = (date: Date | string): number => {
  checkValidDate(date);
  return dayjs(date).unix();
};

/**
 * Convert date string to start of day
 * @param date - The date to be converted.
 * @returns The start of the day for the given date.
 */
export const convertDateStringToStartOfDay = (date: string): Date => {
  checkValidDate(date);
  return dayjs(date)?.startOf('day')?.toDate();
};

/**
 * Get the date for the given number of time units before the current date in the given timezone.
 * @param numberOfUnits - The number of time units before the current date.
 * @param timezone - The timezone to be used.
 * @returns The date for the given number of time units before the current date in the given timezone.
 */
export const getDateBeforeCurrentDate = (
  numberOfUnits: number,
  unit: dayjs.ManipulateType,
  timezone: TimeZoneEnum
): Date => {
  return dayjs().tz(timezone).subtract(numberOfUnits, unit).toDate();
};

/**
 * Get the date for the given number of time units after the current date in the given timezone.
 * @param numberOfUnits - The number of time units after the current date.
 * @param timezone - The timezone to be used.
 * @returns The date for the given number of time units after the current date in the given timezone.
 */
export const getDateAfterCurrentDate = (
  numberOfUnits: number,
  unit: dayjs.ManipulateType,
  timezone: TimeZoneEnum
): Date => {
  return dayjs().tz(timezone).add(numberOfUnits, unit).toDate();
};

/**
 * Get the date for the given number of time units after the given date.
 * @param date - The date to be used.
 * @param numberOfDays - The number of time units after the given date.
 * @returns The date for the given number of time units after the given date.
 */
export const getDateAfterGivenDate = (
  date: Date | string,
  numberOfUnits: number,
  unit: dayjs.ManipulateType,
  timezone?: TimeZoneEnum
): Date => {
  checkValidDate(date);
  return dayjs(date).tz(timezone).add(numberOfUnits, unit).toDate();
};

/**
 * Get the date for the given number of time units before the given date.
 * @param date - The date to be used.
 * @param numberOfDays - The number of time units before the given date.
 * @returns The date for the given number of time units before the given date.
 */
export const getDateBeforeGivenDate = (
  date: Date | string,
  numberOfUnits: number,
  unit: dayjs.ManipulateType
): Date => {
  checkValidDate(date);
  return dayjs(date).subtract(numberOfUnits, unit).toDate();
};

/**
 * Generate a random ISO duration string.
 * @param maxHours
 * @param maxMinutes
 * @param maxSeconds
 * @returns A random ISO duration string in format PThHmMsS.
 */
export const generateRandomISODuration = (maxHours = 24, maxMinutes = 60, maxSeconds = 60) => {
  const duration = moment.duration({
    hours: Math.floor(Math.random() * maxHours),
    minutes: Math.floor(Math.random() * maxMinutes),
    seconds: Math.floor(Math.random() * maxSeconds),
  });

  const isoDuration = duration.toISOString();

  return isoDuration;
};

/**
 * Generate a fixed ISO duration string.
 * @param hours
 * @param minutes
 * @param seconds
 * @returns A fixed ISO duration string in format PThHmMsS.
 */
export const generateFixedISODuration = (hours = 0, minutes = 0, seconds = 0) => {
  const duration = moment.duration({
    hours,
    minutes,
    seconds,
  });

  const isoDuration = duration.toISOString();

  return isoDuration;
};

/**
 * Convert the given date from given timezone to different timezone.
 * @param date - The date to be converted.
 * @param originalTimezone - The timezone of the given date.
 * @returns The date in the toTimezone.
 */
export const convertDateToUtc = (date: Date | string, originalTimezone: TimeZoneEnum): Date => {
  checkValidDate(date);
  const dateWithOriginalTimezone = dayjs.tz(date, originalTimezone);
  return dateWithOriginalTimezone.utc().toDate();
};
