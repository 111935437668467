import { AffordabilityItemDto, NetworthSourceDisplayNameEnum, SelectedSourceEnum } from '@harmoney/api-interfaces';

export const getMonthlyAffordabilityValue = (
  networthSource: NetworthSourceDisplayNameEnum,
  selectedSource: SelectedSourceEnum,
  itemCategory: AffordabilityItemDto[],
  valueIsShared: boolean = false // default value is false
): number => {
  const source = itemCategory?.find((source) => source?.source === networthSource);
  if (!source) return null;
  const monthlyNetValues = source?.monthlyNetValues;
  return valueIsShared
    ? monthlyNetValues?.find((item) => item?.id === selectedSource && item?.isShared)?.value
    : monthlyNetValues?.find((item) => item?.id === selectedSource && !item?.isShared)?.value;
};

export const getFinalValue = (
  networthSource: NetworthSourceDisplayNameEnum,
  itemCategory: AffordabilityItemDto[],
  valueIsShared: boolean = false, // default value is false
  isIncomeTable: boolean = false // default value is false
): number => {
  const source = itemCategory?.find((source) => source?.source === networthSource);
  if (!source) return null;

  const isSelectedAndShared: boolean = source?.monthlyNetValues?.some((item) => item?.isSelected && item?.isShared);
  const isSelectedAndNotShared: boolean = source?.monthlyNetValues?.some((item) => item?.isSelected && !item?.isShared);

  if (valueIsShared) {
    if (source?.finalSharedValue) return source?.finalSharedValue;
    if (isSelectedAndShared) {
      return isIncomeTable ? source?.totalIncoming : source?.totalOutgoing;
    }
  } else {
    if (isSelectedAndNotShared) {
      return isIncomeTable ? source?.totalIncoming : source?.totalOutgoing;
    }
  }

  return 0;
};

export const valueIsSelected = (
  selectedSource: SelectedSourceEnum,
  networthSource: NetworthSourceDisplayNameEnum,
  itemCategory: AffordabilityItemDto[],
  valueIsShared: boolean = false // default value is false
): boolean => {
  const source = itemCategory?.find((source) => source?.source === networthSource);
  if (!source) return false;
  return valueIsShared
    ? source?.monthlyNetValues?.find((item) => item?.id === selectedSource && item?.isShared)?.isSelected
    : source?.monthlyNetValues?.find((item) => item?.id === selectedSource && !item?.isShared)?.isSelected;
};

export const rowIsGrey = (row: RowData) => {
  return Object.values(SelectedSourceEnum).every((key) => !row[key]?.value);
};

export type RowData = {
  title: string;
  ai?: RowItem;
  final: RowItem;
  benchmark?: RowItem;
  declared?: RowItem;
  bankStatement: RowItem;
  override: RowItem;
  minAcceptableValue?: RowItem;
};

type RowItem = {
  value: string;
  isSelected?: boolean;
};
