import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import {
  useGetQuoteOptionsForUnutilisedLimitQuery,
  useSubmitLoanApplicationForUnutilisedLimitMutation,
  useUpdateLoanPurposesMutation,
} from '@harmoney/redux';
import { useGetLoanPurposesForPersonalLoanQuery } from '@harmoney/redux';
import { ControlledSelect } from '@harmoney/ui-design-system';
import { omit } from 'lodash';
import { z } from 'zod';

import { CommonProps } from '../../common-props';
import { createFormSchemaForUnutilisedLimit, defaultValues } from '../PersonalLoanQuote';
import { PersonalLoanQuote } from '../PersonalLoanQuote/PersonalLoanQuote';
import { formatLoanPurposesForUnutilisedLimit } from '../shared';

/**
 * @deprecated This component is deprecated and will be removed
 * when there are no active process instances using it.
 */
export function ValidQuoteForUnutilisedLimitV2(props: CommonProps) {
  const [updateLoanPurposes] = useUpdateLoanPurposesMutation();
  const [submitLoanApplicationForUnutilisedLimit] = useSubmitLoanApplicationForUnutilisedLimitMutation();
  const { data: loanPurposesData } = useGetLoanPurposesForPersonalLoanQuery(null);

  const submitLoanApplication = async (data: {
    id: string;
    fundedAmount: number;
    loanPurposeForUnutilisedLimit: string;
  }) => {
    const loanPurpose = {
      id: data.loanPurposeForUnutilisedLimit,
      requestedAmount: +data.fundedAmount,
      fundedAmount: +data.fundedAmount,
    };
    await updateLoanPurposes({
      applicationId: data.id,
      loanPurposes: [loanPurpose],
    });

    return submitLoanApplicationForUnutilisedLimit({
      id: data.id,
      ...omit(data, ['loanPurposeForUnutilisedLimit', 'id']),
    }).unwrap();
  };

  const availableLoanPurposes = React.useMemo(() => {
    if (!loanPurposesData) return [];
    return formatLoanPurposesForUnutilisedLimit(loanPurposesData);
  }, [loanPurposesData]);

  const AdditionalFields = ({
    register,
  }: {
    register: UseFormRegister<z.infer<ReturnType<typeof createFormSchemaForUnutilisedLimit>>>;
  }) => {
    return (
      <ControlledSelect
        {...register('loanPurposeForUnutilisedLimit')}
        label="What will you use the money for?"
        options={availableLoanPurposes}
        className="m-4"
      />
    );
  };

  return (
    <PersonalLoanQuote
      {...props}
      isUnutilisedLimitLoan={true}
      createFormSchema={createFormSchemaForUnutilisedLimit}
      defaultValues={defaultValues}
      submitLoanApplication={submitLoanApplication}
      AdditionalFields={AdditionalFields}
      useGetQuoteOptionsQuery={useGetQuoteOptionsForUnutilisedLimitQuery}
      quoteData={null}
      loanPurposesData={loanPurposesData}
    />
  );
}
