import { useState } from 'react';
import {
  ArrowCircleRightIcon,
  Button,
  Card,
  ClockLightningIcon,
  IconV2,
  InformationBox,
} from '@harmoney/ui-design-system';

import { CommonProps } from '../../common-props';

const informationBoxItems = [
  {
    icon: <IconV2 icon="mdi:person-check-outline" className="text-secondary" width={24} />,
    text: 'Your ID that you used during ID verification (Valid Australian passport /driver’s licence)',
  },
  {
    icon: <IconV2 icon="ic:baseline-mobile-friendly" className="text-secondary" width={24} />,
    text: "Make sure that your phone's camera is functional to capture photos or videos",
  },
  {
    icon: <ClockLightningIcon />,
    text: '3 min of your time to complete this step',
  },
];

export function OnfidoWelcome({ taskId, completeTaskWithData }: CommonProps) {
  const [isSubmitting] = useState<boolean>(false);
  const handleSubmit = async () => {
    await completeTaskWithData({ taskId });
  };

  return (
    <>
      <h1>
        Before you <span className="text-primary">continue</span>
      </h1>

      <p>
        We take security seriously, which is why we ask for verification of your identity before deciding on your loan
        application.
      </p>

      <Card className="!p-0">
        <InformationBox title="You’ll need:" items={informationBoxItems} />
      </Card>

      <div className="flex justify-center">
        <Button
          type="submit"
          alignIcon="end"
          variant="primary"
          icon={<ArrowCircleRightIcon size="large" />}
          isLoading={isSubmitting}
          onClick={handleSubmit}
          hasShadow
        >
          I&rsquo;m ready, continue
        </Button>
      </div>
    </>
  );
}
