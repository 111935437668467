import { DirectDebitHistory, UserDto } from '@harmoney/api-interfaces';
import { Address, UserProfile } from '@prisma/client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AdminState {
  user: UserDto;
  userProfile: Partial<UserProfile & { addresses: Address[] }>;
  activePage: string;
  selectedDirectDebitHistory?: DirectDebitHistory;
  actionCenter: {
    isCreditAuditConducted?: boolean;
    isDealRescued?: boolean;
    isInterestRateOverrideOpen?: boolean;
    isLoanVariationApplied?: boolean;
    isDirectDebitSuspendOpen?: boolean;
    isScheduleExtraPaymentOpen?: boolean;
    showVariationHistory?: boolean;
    showChangeSummary?: boolean;
    showUpdateDirectDebitPlan?: boolean;
    showUpdateCollectionStatus?: boolean;
    isBlockAccessToRepeatOpen?: boolean;
    isUpdateDirectDebitAccountOpen?: boolean;
    createWriteOff?: boolean;
    editWriteOff?: boolean;
    showOneDirectDebitHistory?: boolean;
  };
}

const initialState: AdminState = {
  user: {
    id: '',
    email: '',
    createdAt: null,
    updatedAt: null,
    preferredName: '',
  },
  userProfile: {
    id: '',
    firstName: '',
    lastName: '',
    addresses: [],
    dateOfBirth: '',
    middleName: '',
  },
  activePage: '',
  actionCenter: {
    isCreditAuditConducted: false,
    isBlockAccessToRepeatOpen: false,
    isUpdateDirectDebitAccountOpen: false,
    isDealRescued: false,
    isInterestRateOverrideOpen: false,
    isLoanVariationApplied: false,
    isDirectDebitSuspendOpen: false,
    isScheduleExtraPaymentOpen: false,
    showVariationHistory: false,
    showChangeSummary: false,
    showUpdateDirectDebitPlan: false,
    showUpdateCollectionStatus: false,
    createWriteOff: false,
    editWriteOff: false,
  },
};

const toggleOtherActions = (state: AdminState, action: keyof AdminState['actionCenter']) => {
  return Object.keys(state.actionCenter)
    .filter(([key]) => key !== action)
    .forEach((key) => {
      state.actionCenter[key] = false;
    });
};

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserDto>) => {
      state.user = action.payload;
    },
    setUserProfile: (state, action: PayloadAction<UserProfile>) => {
      state.userProfile = action.payload;
    },
    setActivePage: (state, action: PayloadAction<string>) => {
      state.activePage = action.payload;
    },
    setIsCreditAuditConducted: (state, action: PayloadAction<boolean>) => {
      action.payload && toggleOtherActions(state, 'isCreditAuditConducted');
      state.actionCenter.isCreditAuditConducted = action.payload;
    },
    setIsBlockAccessToRepeatOpen: (state, action: PayloadAction<boolean>) => {
      action.payload && toggleOtherActions(state, 'isBlockAccessToRepeatOpen');
      state.actionCenter.isBlockAccessToRepeatOpen = action.payload;
    },
    setIsUpdateDirectDebitAccountOpen: (state, action: PayloadAction<boolean>) => {
      action.payload && toggleOtherActions(state, 'isUpdateDirectDebitAccountOpen');
      state.actionCenter.isUpdateDirectDebitAccountOpen = action.payload;
    },
    setIsDealRescued: (state, action: PayloadAction<boolean>) => {
      action.payload && toggleOtherActions(state, 'isDealRescued');
      state.actionCenter.isDealRescued = action.payload;
    },
    setIsInterestRateOverrideOpen: (state, action: PayloadAction<boolean>) => {
      action.payload && toggleOtherActions(state, 'isInterestRateOverrideOpen');
      state.actionCenter.isInterestRateOverrideOpen = action.payload;
    },
    setIsLoanVariationApplied: (state, action: PayloadAction<boolean>) => {
      action.payload && toggleOtherActions(state, 'isLoanVariationApplied');
      state.actionCenter.isLoanVariationApplied = action.payload;
    },
    setIsDirectDebitSuspendOpen: (state, action: PayloadAction<boolean>) => {
      action.payload && toggleOtherActions(state, 'isDirectDebitSuspendOpen');
      state.actionCenter.isDirectDebitSuspendOpen = action.payload;
    },
    setIsScheduleExtraPaymentOpen: (state, action: PayloadAction<boolean>) => {
      action.payload && toggleOtherActions(state, 'isScheduleExtraPaymentOpen');
      state.actionCenter.isScheduleExtraPaymentOpen = action.payload;
    },
    setShowVariationHistory: (state, action: PayloadAction<boolean>) => {
      action.payload && toggleOtherActions(state, 'showVariationHistory');
      state.actionCenter.showVariationHistory = action.payload;
    },
    setShowChangeSummary: (state, action: PayloadAction<boolean>) => {
      action.payload && toggleOtherActions(state, 'showChangeSummary');
      state.actionCenter.showChangeSummary = action.payload;
    },
    setShowUpdateDirectDebitPlan: (state, action: PayloadAction<boolean>) => {
      action.payload && toggleOtherActions(state, 'showUpdateDirectDebitPlan');
      state.actionCenter.showUpdateDirectDebitPlan = action.payload;
    },
    setShowUpdateCollectionStatus: (state, action: PayloadAction<boolean>) => {
      action.payload && toggleOtherActions(state, 'showUpdateCollectionStatus');
      state.actionCenter.showUpdateCollectionStatus = action.payload;
    },
    setCreateWriteOff: (state, action: PayloadAction<boolean>) => {
      action.payload && toggleOtherActions(state, 'createWriteOff');
      state.actionCenter.createWriteOff = action.payload;
    },
    setEditWriteOff: (state, action: PayloadAction<boolean>) => {
      action.payload && toggleOtherActions(state, 'editWriteOff');
      state.actionCenter.editWriteOff = action.payload;
    },
    setSelectedDirectDebitHistory: (state, action: PayloadAction<DirectDebitHistory>) => {
      state.selectedDirectDebitHistory = action.payload;
      state.actionCenter.showOneDirectDebitHistory = !!action.payload;
    },
  },
});

export const {
  setUser,
  setUserProfile,
  setActivePage,
  setIsCreditAuditConducted,
  setIsBlockAccessToRepeatOpen,
  setIsUpdateDirectDebitAccountOpen,
  setIsDealRescued,
  setIsInterestRateOverrideOpen,
  setIsLoanVariationApplied,
  setIsDirectDebitSuspendOpen,
  setIsScheduleExtraPaymentOpen,
  setShowVariationHistory,
  setShowChangeSummary,
  setShowUpdateDirectDebitPlan,
  setShowUpdateCollectionStatus,
  setCreateWriteOff,
  setEditWriteOff,
  setSelectedDirectDebitHistory,
} = adminSlice.actions;

export default adminSlice.reducer;
