import { useMemo } from 'react';
import { CompleteTaskDto, FORM_KEY } from '@harmoney/api-interfaces';
import { useBranch, useFriendlyURL } from '@harmoney/hooks';
import { ArrowCircleRightIcon, Button, Card, ControlledInput, Form, Label, useForm } from '@harmoney/ui-design-system';
import { BranchEnum } from '@prisma/client';

import { CommonProps } from '../../common-props';

import { Country, createFormSchema } from './form-config';

export const RegionalText: Record<BranchEnum, Country> = {
  [BranchEnum.AU]: {
    countryCode: '+61',
    countryPhoneLabel: 'Australian',
    minLength: 9,
    maxLength: 10,
    firstDigit: '4',
  },
  [BranchEnum.NZ]: {
    countryCode: '+64',
    countryPhoneLabel: 'New Zealand',
    minLength: 8,
    maxLength: 10,
    firstDigit: '2',
  },
};

export function PhoneNumberVerification({ taskId, completeTaskWithData, taskFriendlyURL }: CommonProps) {
  const branch = useBranch();

  const handleSubmit = async (data) => {
    const completeTaskDto: CompleteTaskDto = {
      taskId,
      formKey: FORM_KEY.USER_PHONE_NUMBER_UPDATE,
      formData: {
        mobilePhoneNumber: data.mobilePhoneNumber,
      },
    };
    await completeTaskWithData(completeTaskDto);
  };

  const prefix = useMemo(() => {
    if (branch) {
      return RegionalText[branch]?.countryCode;
    }
  }, [branch]);

  const formSchema = createFormSchema({ branch, prefix, RegionalText });

  const form = useForm({
    mode: 'onTouched',
    schema: formSchema,
    defaultValues: {
      mobilePhoneNumber: '',
    },
  });

  const {
    register,
    formState: { isSubmitting, isSubmitSuccessful },
  } = form;

  useFriendlyURL(taskFriendlyURL);

  return (
    <div className="flex flex-col">
      <h1>
        What is your <span className="text-primary">phone number</span>?
      </h1>
      <Form form={form} onSubmit={handleSubmit}>
        <Card>
          <Label className="mb-4">Please enter your {RegionalText[branch]?.countryPhoneLabel} phone number</Label>
          <ControlledInput
            prefix={prefix}
            {...register('mobilePhoneNumber')}
            type="text"
            maxLength={RegionalText[branch]?.maxLength}
            isNumeric
          />
        </Card>
        <Button
          alignIcon="end"
          icon={<ArrowCircleRightIcon size="large" />}
          variant="primary"
          type="submit"
          className="mt-6"
          isLoading={isSubmitting || isSubmitSuccessful}
          hasShadow
        >
          Continue
        </Button>
      </Form>
    </div>
  );
}
